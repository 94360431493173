import React from 'react'
// Node
import moment from 'moment'
// Components
import MaterialTimePicker from '../../../../material/MaterialTimePicker'

/*
 * Компонент "Поле формы "Время""
 */

// Экспорт компонента
export const TimeFormField = (props) => {
    // Преобразование пропсов в локальные константы
    const { formik, fieldKey, fieldProps, changedFields, setChangedFields } = props

    const [selectTime, setSelectTime] = React.useState(
        Object.prototype.hasOwnProperty.call(formik.initialValues, fieldKey)
            ? formik.initialValues[fieldKey]
            : null,
    )
    const handleTimeChange = (value: string) => {        
        const regExpTime = /^\d{1,2}:\d{1,2}$/    
        const timeStr = moment(value).format("HH:mm")
        const formatedValue = regExpTime.test(timeStr) ? timeStr : null     

        if (!changedFields.includes(fieldKey)) {
            setChangedFields([...changedFields, fieldKey])
        }
        setSelectTime(value)
        formik.setFieldValue(fieldKey, formatedValue)
    }

    return <MaterialTimePicker {...fieldProps} time={selectTime} onChange={handleTimeChange} />
}

// Свойства по умолчанию
TimeFormField.defaultProps = {}

// Экспорт компонента
export default TimeFormField
