// Редьюсеры
import loadReducer from './load-reducer'
import articlesReducer from './articles-reducer'
import slidersReducer from './sliders-reducer'
import menuReducer from './menu-reducer'
import calendarReducer from './calendar-reducer'
import usersReducer from './users-reducer'
import messagesReducer from './messages-reducer'
import coachesReducer from './coaches-reducer'

// Корневой редьюсер
const rootReducer =  {    
    loadStore: loadReducer,
    articlesStore: articlesReducer,
    slidersStore: slidersReducer,
    menuStore: menuReducer,
    calendarStore: calendarReducer,
    usersStore: usersReducer, 
    messagesStore: messagesReducer, 
    coachesStore: coachesReducer, 
}

// Экспорт корневого редьюсера
export default rootReducer