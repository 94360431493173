import React from 'react'
// Node
import moment from 'moment'
import { Box, Tooltip } from '@mui/material'
import ReactDOM from 'react-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

/*
 * Компонент "Календарь" (обертка над FullCalendar)
 */

// Экспорт компонента
export const Calendar = (props) => {
    // Преобразование пропсов в локальные константы
    const { view, events, weekends, headerToolbar, locale, timeZone, slotMinTime,
        slotLabelFormat, dayHeaderFormat, buttonText, allDaySlot, allDayText, height, 
        eventClick, cellClick} = props    
    
    return(     
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView='timeGridWeek'
            events={events}
            weekends={weekends}            
            headerToolbar={headerToolbar}
            locale={locale}
            timeZone={timeZone}
            slotMinTime={slotMinTime}
            slotLabelFormat={slotLabelFormat}      
            dayHeaderFormat={dayHeaderFormat}
            buttonText={buttonText}
            allDaySlot={allDaySlot}
            allDayText={allDayText}
            height={height}
            selectable={true}            
            editable={view === 'admin' ? false : false}
            eventClick={ (info) => eventClick(info.event) }
            select={ (info) => cellClick(info) }
            eventDidMount={ 
                (info) => {
                    const start_time = moment(info.event.start).zone(0).format("HH:mm")
                    const end_time = moment(info.event.end).zone(0).format("HH:mm")
                    
                    ReactDOM.render(
                        <Tooltip 
                            title={
                                <>
                                    <Box>Sección: {info.event.title}</Box>
                                    <Box>Estado: {info.event.extendedProps.status}</Box>
                                    <Box>Hora de inicio: {start_time}</Box>
                                    <Box>Нora de finalización: {end_time}</Box>
                                    {info.event.extendedProps.age
                                        ? <Box>Grupo: {info.event.extendedProps.age}</Box>
                                        : []
                                    }
                                    {info.event.extendedProps.section_id === Number(process.env.REACT_APP_JOOMLA_RENT_SECTION)
                                        && view === 'admin'
                                        ? <>
                                            <Box>Nombre completo: {info.event.extendedProps.user_full_name}</Box>
                                            <Box>Dirección de correo electrónico: {info.event.extendedProps.user_email}</Box>
                                            <Box>Número de teléfono: {info.event.extendedProps.user_phone}</Box>
                                        </>
                                        : []
                                    }
                                </>
                            }
                            placement={'top'}
                            arrow>
                            <Box 
                                className="fc-event-main-frame" 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: '100%',
                                    poition: 'absolute',
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <Box className="fc-event-time">
                                    <Box sx={{float: 'left'}}>{start_time}</Box>
                                    <Box sx={{float: 'left'}}>-</Box>
                                    <Box sx={{float: 'left'}}>{end_time}</Box>
                                </Box>
                                <Box 
                                    className="fc-event-icon"
                                    sx={{
                                        paddingTop: '2px',                                       
                                    }}
                                >
                                    {info.event.extendedProps.icon}
                                </Box>                                
                            </Box>
                        </Tooltip>,
                        info.el.querySelector('.fc-event-main')
                    )
                    
                    return info.el
                }
            }
        />        
    )
}

// Свойства по умолчанию
Calendar.defaultProps = {
    view: 'main',
    weekends: true,
    headerToolbar: {
        left: 'title',
        center: '',
        right: 'today prev,next',
    },
    locale: 'es',
    timeZone: 'Asia/Almaty',
    slotMinTime: '09:00:00',
    slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
    },
    dayHeaderFormat: {
        weekday: 'short', 
        day: 'numeric', 
        omitCommas: true,
    },
    buttonText: {
        today: 'hoy'       
    },
    allDaySlot: false,
    allDayText: '',
    events: [],
    height: 'auto',
}

// Экспорт компонента
export default Calendar