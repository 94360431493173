export const getFields = () => {    
    const fields = {
        divider1: { title: 'Información del grupo de edad', type: 'divider' },        
        section_id: { title: 'Sección', type: 'select', format: 'number' },
        gender_id: { title: 'Gender', type: 'select', format: 'number' },
        name: { title: 'Nombre del grupo', type: 'text', format: 'string' }        
    }

    return fields
}
