import React from "react"
// Node
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'
// Redux
import { getArticle } from '../../../redux/reducers/articles-reducer'


/*
 * Страница "Главная"
 */

// Компонент страницы
const MainPage = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentArticle, currentArticleId, loadingArticleComplete, getArticle } = props
    
    // Хук эффекта
    React.useEffect(() => {        
        // Получить статью   
        getArticle(currentArticleId)
    }, [getArticle, currentArticleId])   
    
    return (               
        <>
            {loadingArticleComplete
                ? <Box sx={{px: 2}}>
                    <Typography sx={{fontFamily: 'Mistral', fontSize: '1.7rem'}}>                    
                        { currentArticle?.attributes?.title }
                    </Typography>
                    <Typography>
                        <div 
                            dangerouslySetInnerHTML={
                                { __html: currentArticle?.attributes?.text || '' }
                            } 
                        />                          
                    </Typography>
                </Box>
                : []
            }
        </>
    )    
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state) => (    
    {        
        currentArticle: state.articlesStore?.currentArticle,
        currentArticleId: state.articlesStore?.currentArticleId,
        loadingArticleComplete: state.loadStore.loadingComplete?.article,        
    }
)

// Экспорт страницы
export default connect(mapStateToProps, {getArticle})(MainPage)