import React from 'react'
// Node
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

/*
 * Компонент "Диалог" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialDialog = (props) => {
    // Преобразование пропсов в локальные константы
    const { showModal, title, content, onClose, onApply, onCloseText, onApplyText,
        showOnCloseButton, showApplyButton, scroll, fullWidth, } = props
    
    return(     
        <Dialog 
            open={ showModal} 
            scroll={scroll} 
            onClose={onClose} 
            fullWidth={fullWidth}
            maxWidth={fullWidth ? 'unset' : 'initial'}
        >
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent>
                { content }
            </DialogContent>
            <DialogActions sx={{px: 3}}>
                { showOnCloseButton
                    ? <Button onClick={onClose}>{ onCloseText }</Button>
                    : []
                }
                { showApplyButton
                    ? <Button onClick={onApply}>{ onApplyText }</Button>
                    : []
                }                
            </DialogActions>
        </Dialog>        
    )
}

// Свойства по умолчанию
MaterialDialog.defaultProps = {
    fullWidth: false,
    showModal: false,
    onCloseText: 'Cancelar',
    onApplyText: 'Aplicar',
    showOnCloseButton: true,
    showApplyButton: true,
    scroll: 'body',
}

// Экспорт компонента
export default MaterialDialog