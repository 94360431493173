import React from "react"
// Node
import { connect } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router'
import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'
import { AccessTime as AccessTimeIcon, CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material'
// Components
import MaterialDialog from '../../components/material/MaterialDialog'
// Redux
import { getUser } from '../../../redux/reducers/users-reducer'
import { deleteEvent, deleteChild } from '../../../redux/reducers/calendar-reducer'

/*
 * Страница "Кабинет"
 */

// Компонент страницы
const CabinetPage = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentUser, loadingUserComplete, getUser, 
        loadingCalendarComplete, loadingChildComplete, 
        deleteEvent, deleteChild } = props
    
    // Тема MUI
    const theme = useTheme()
    
    // Получение параметра hash из УРЛ
    const { hash } = useParams()            

    // Хук эффекта
    React.useEffect(() => {        
        // Получить пользователя   
        getUser(hash)
    }, [getUser, hash, loadingCalendarComplete, loadingChildComplete])    
    
    // Извлечение данных пользователя из атрибутов
    const userData = currentUser?.attributes
    
    // Установка локального значения открытия диалога удаления
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
        // Установка объекта с информацией об удалении объекта
    const [deleteInfo, setDeleteInfo] = React.useState({})
    
    return (               
        <Box sx={{px: 2}}>
            <Typography sx={{fontFamily: 'Mistral', fontSize: '1.7rem'}}>                    
                Área personal
            </Typography>
            <Typography>
                {loadingUserComplete
                    ? <> 
                        {currentUser
                            ? <> 
                                <Alert sx={{mt: 2}} icon={false} severity="info">
                                    <AlertTitle>Bienvenido</AlertTitle>
                                    <Typography>Aquí puedes gestionar tu reserva!</Typography>
                                    <Typography>
                                        Reserva a su correo electrónico coincide <strong>{userData?.email}</strong> con un hash único: <strong>{userData?.hash}</strong>
                                    </Typography>
                                </Alert>
                                <Alert sx={{mt: 2}} variant="outlined" icon={false} severity="error">                                   
                                    <Typography color={'primary'}>Informacion personal:</Typography>
                                    <Typography>Nombre: {userData?.full_name}</Typography>
                                    <Typography>Correo electrónico: {userData?.email}</Typography>
                                    <Typography>Teléfono: {userData?.phone}</Typography>                                   
                                </Alert>
                                {userData?.books.length
                                    ? <Box sx={{mt: 3}}>
                                        <Typography  color={'secondary'}>Lista de reserva de tiempo de gimnasio gratis:</Typography>                                            
                                    </Box>
                                    : []
                                }
                                {userData?.books?.map(book =>
                                    <Alert 
                                        sx={{
                                            mt: 2, 
                                            borderColor: 
                                                Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                                ? theme.palette.grey.main
                                                : Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                    ? theme.palette.green.main : 'initial',                                 
                                        }}
                                        iconMapping={{
                                            success: <CheckCircleOutlineIcon fontSize="inherit" />,
                                            error: <AccessTimeIcon fontSize="inherit" />
                                        }}                                        
                                        variant="outlined" 
                                        severity={
                                            Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                            ? 'error'
                                            : Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                ? 'success' : 'success'                                                                         
                                        }
                                    >                                        
                                        <Box sx={{mt: 1}}>
                                            <Typography  color={'primary'}>Detalles de la reserva:</Typography>
                                            <Typography>Número de gimnasio: 
                                                {` #${book?.hall_id}`}
                                            </Typography>
                                            <Typography>Inicio del entrenamiento: 
                                                {` ${moment(book?.start_time).format('DD.MM.YYYY')} - ${moment(book?.start_time).format('HH:mm')}`}
                                            </Typography>
                                            <Typography>Fin del entrenamiento: 
                                                {` ${moment(book?.end_time).format('DD.MM.YYYY')} - ${moment(book?.end_time).format('HH:mm')}`}
                                            </Typography>
                                            <Typography>Estado de la reservación: 
                                                {` ${
                                                    Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                                    ? 'Reservado'
                                                    : Number(book?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                        ? 'Ocupado' : ''
                                                }`}
                                            </Typography>                                            
                                        </Box>
                                        <Box sx={{mt: 1}}>
                                            <Button
                                                variant={'outlined'}
                                                color={'secondary'}
                                                onClick={() => {
                                                    setDeleteInfo({type: 'book', id: book?.id})                                                
                                                    setShowDeleteModal(true)
                                                }}
                                            >
                                                Cancelar reserva
                                            </Button>                                             
                                        </Box>
                                    </Alert>
                                )}
                                {userData?.children.length
                                    ? <Box sx={{mt: 3}}>
                                        <Typography  color={'secondary'}>Lista de reserva de secciones para su hijo:</Typography>                                            
                                    </Box>
                                    : []
                                }
                                {userData?.children?.map(child =>
                                    <Alert 
                                        sx={{
                                            mt: 2, 
                                            borderColor: 
                                                Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                                ? theme.palette.grey.main
                                                : Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                    ? theme.palette.green.main : 'initial',                                 
                                        }}
                                        iconMapping={{
                                            success: <CheckCircleOutlineIcon fontSize="inherit" />,
                                            error: <AccessTimeIcon fontSize="inherit" />
                                        }}                                        
                                        variant="outlined" 
                                        severity={
                                            Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                            ? 'error'
                                            : Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                ? 'success' : 'success'                                                                         
                                        }
                                    >
                                        <Box sx={{mt: 1}}>
                                            <Typography  color={'primary'}>Detalles de la reserva:</Typography>
                                            <Typography>Sección: 
                                                {` ${
                                                    Number(child?.section_id) === Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)
                                                    ? 'Baloncesto'
                                                    : Number(child?.section_id) === Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)
                                                        ? 'Gimnasia' : ''
                                                }`}
                                            </Typography>
                                            <Typography>Grupo de edad: 
                                                {` ${child?.gender_name} - ${child?.age_name}`}
                                            </Typography>
                                            <Typography>Nombre completo del niño: 
                                                {` ${child?.child_full_name}`}
                                            </Typography>
                                            <Typography>Edad del niño: 
                                                {` ${child?.child_age}`}
                                            </Typography>
                                            <Typography>Estado de la reservación: 
                                                {` ${
                                                    Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                                    ? 'Reservado'
                                                    : Number(child?.status_id) === Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)
                                                        ? 'Ocupado' : ''
                                                }`}
                                            </Typography>                                            
                                        </Box>
                                        <Box sx={{mt: 1}}>
                                            <Button
                                                variant={'outlined'}
                                                color={'secondary'}
                                                onClick={() => {
                                                    setDeleteInfo({type: 'child', id: child?.id})                                                
                                                    setShowDeleteModal(true)
                                                }}
                                            >
                                                Cancelar reserva
                                            </Button>                                             
                                        </Box>
                                    </Alert>
                                )}
                                <MaterialDialog title="Cancelar la reserva"
                                    content="De verdad quieres cancelar?"
                                    showModal={showDeleteModal}
                                    onApplyText={'Sí'}
                                    onCloseText={'No'}                                    
                                    onApply={() => { 
                                        setShowDeleteModal(false)                    
                                        if(deleteInfo?.type === 'book') {                                                               
                                            deleteEvent(deleteInfo?.id)                                                                    
                                        }
                                        if(deleteInfo?.type === 'child') {
                                            deleteChild(deleteInfo?.id)                                            
                                        }                    
                                    }}
                                    onClose={() => { setShowDeleteModal(false) }} 
                                />
                            </>
                            : <>
                                <Alert sx={{mt: 2}} severity="error">
                                    <AlertTitle>Lo siento</AlertTitle>                            
                                    <Typography>
                                        Reserva para hash: <strong>{hash}</strong> no encontrada.
                                    </Typography>
                                </Alert>                    
                            </>
                        }
                    </>
                    : <Backdrop
                        sx={{ color: '#fff', zIndex: 2 }}
                        open={!loadingCalendarComplete || !loadingChildComplete}                
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </Typography>
        </Box>
    )    
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state) => (    
    {        
        currentUser: state.usersStore?.currentUser,        
        loadingUserComplete: state.loadStore.loadingComplete?.user,
        loadingCalendarComplete: state.loadStore.loadingComplete?.calendar,
        loadingChildComplete: state.loadStore.loadingComplete?.child,
    }
)

// Экспорт страницы
export default connect(mapStateToProps, {getUser, deleteEvent, deleteChild})(CabinetPage)