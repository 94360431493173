//Node
import { styled } from '@mui/material/styles';

/*
 * Стилизованный компонент "Карточка"
 */

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)'
});

// Экспорт компонента
export default CardMediaStyle;