// Node
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';

// Конструктор стилей
const useStyleMui = () => {    
    // Использовать текущую тему
    const theme= useTheme();
    
    const LinkSize = '20px'
    const LinkColor = '#d73f2e'    
    
    return makeStyles(() => ({
        wrap: {                        
            borderRadius: '5px',
            bottom: '80px',
            color: '#fff',
            cursor: 'pointer',  
            display: 'grid', 
            height: `${LinkSize}`,  
            overflow: 'hidden', 
            padding: '2px 15px 7px 15px',
            position: 'fixed',
            right: '20px',
            [theme.breakpoints.up('lg')]: {
                right: '80px',
            },           
            width: 'auto',
            zIndex: 10,
            '&:hover': {
                opacity: '0.8',
            }            
        },        
        linkCover: {
            background: `${LinkColor}`,
            display: 'block',
            height: '100%',
            opacity: '0.7',
            position: 'absolute',
            width: '100%',
        },
        linkWrap: {
            display: 'grid',
            height: '100%',
            width: '100%',
            zIndex: 11,
            '& a': {
                color: '#fff',
                textAlign: 'center',
                textTransform: 'uppercase',
                lineHeight: `${LinkSize}`,   
            },              
        },        
    }))();   
}

// Экспорт конструктора стилей
export default useStyleMui;
