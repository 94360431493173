// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { slidersAPI } from '../../api/endpoints/slidersAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Текущий слайдер
    currentSlider: null,    
}

// Слайс слайдеров
const sliderSlice = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        // Установить текущий слайдер
        setCurrentSlider(state, action) {                   
            state.currentSlider = action.payload
        },        
    }
})

// Экспорт создателей действий
export const { setCurrentSlider } = sliderSlice.actions;
// Экспорт редьюсера
export default sliderSlice.reducer;

//THUNK CREATORS

// Получение слайдера
export const getSlider = () => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('slider'))
                        
        // Получение данных через API слайдеров
        slidersAPI.getSlider().then((response) => {                        
            // Установить полученную информацию
            dispatch(setCurrentSlider(response))                      
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('slider'))
        })
    }
}