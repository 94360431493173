// Объект значений полей
export const values = { 
    section_id: {
        options: [],
    },
    gender_id: {
        options: [],
    },    
}

// Объект начальных значений полей
export const initialValues = { }

// Объект правил сокрытия полей
export const valuesHideRule = { }

// Массив полей, переключающих режим видимости кнопки отправки формы
export const submitButtonDisableFields = []
