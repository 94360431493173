import React from "react"
// Node
import { SportsBasketball as SportsBasketballIcon, SportsGymnastics as SportsGymnasticsIcon, 
    Groups as GroupsIcon, KeyboardArrowRight as KeyboardArrowRightIcon} from '@mui/icons-material'

/*
 * ПАЛИТРА
 * #e67e23 - оранжевый
 * #843fa1 - фиолетовый
 * #2dc26b - зеленый
 * #909090 - серый
 */

// Получить события календаря
export const getCalendarEvents = (props) => {
    // Преобразование пропсов в локальные константы        
    const { eventList } = props
    
    // Стиль иконок
    const iconsStyle={color: '#ffffff'}
    
    // События календаря
    const calendarEvents = []
    
    if(eventList?.length) {
        eventList?.forEach(event => {
            let color = '#843fa1'
            let title = ''
            let status = 'desconocido'
            let age = ''
            let user_full_name = ''
            let user_email = ''
            let user_phone = ''
            let icon = <KeyboardArrowRightIcon />
            // Установка информации в зависимости от типа секции
            switch(event?.attributes?.section_id) {
                // Баскетбол
                case Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION) : 
                    color = '#e67e23'; title = 'baloncesto'
                    age = `${event?.attributes?.gender} - ${event?.attributes?.age}`
                    icon = <SportsBasketballIcon sx={{...iconsStyle}} />
                    break
                // Гимнастика
                case Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION) : 
                    color = '#843fa1'; title = 'gimnasia'
                    age = `${event?.attributes?.gender} - ${event?.attributes?.age}`
                    icon = <SportsGymnasticsIcon sx={{...iconsStyle}} />
                    break
                // Аренда
                case Number(process.env.REACT_APP_JOOMLA_RENT_SECTION) : 
                    color = '#2dc26b'; title = 'alquilar'
                    user_full_name = event?.attributes?.user_full_name
                    user_email = event?.attributes?.user_email
                    user_phone = event?.attributes?.user_phone
                    icon = <GroupsIcon sx={{...iconsStyle}} />
                    break                                             
                default: ;
            }
            // Установка информации в зависимости от статуса бронирования
            switch(event?.attributes?.status_id) {
                // Свободно
                case Number(process.env.REACT_APP_JOOMLA_FREE_STATUS) : 
                    status = 'gratis'; break
                // Заброниовано
                case Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS) : 
                    status = 'reservado'; color = '#909090'; break
                // Занято
                case Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS) : 
                    status = 'ocupado'; break                                             
                default: ;
            }            
            
            calendarEvents.push({
                start: event?.attributes?.start_time,
                end: event?.attributes?.end_time,
                color,
                title,
                extendedProps: {
                    book_id: event?.attributes?.id,
                    section_id: event?.attributes?.section_id,
                    status_id: event?.attributes?.status_id,
                    icon, status, age, 
                    user_full_name,
                    user_email,
                    user_phone,
                    start_time: event?.attributes?.start_time,
                    end_time: event?.attributes?.end_time,
                },
            })
        })
    }
    
    return calendarEvents    
}