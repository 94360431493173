// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { calendarAPI } from '../../api/endpoints/calendarAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Айди зала
    hallId: 1,
    // Айди секции
    sectionId: null,
    // Айди пола
    genderId: null,
    
    // Режим бронирования
    bookMode: 'main',
    
    // Список событий
    eventList: null,
    // Список детей
    childrenList: null,
    // Список возрастных групп
    ageList: null,
    
    // Текущее событие
    currentEvent: {},
    // Текущие дети
    currentChild: [],    
}

// Слайс календаря
const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        // Установить айди зала
        setHallId(state, action) {                   
            state.hallId = action.payload
        },
        // Установить айди секции
        setSectionId(state, action) {                   
            state.sectionId = action.payload
        },
        // Установить айди пола
        setGenderId(state, action) {                   
            state.genderId = action.payload
        },
        
        
        // Установить текущее событие
        setCurrentEvent(state, action) {                   
            state.currentEvent = action.payload
        },
        // Установить текущего ребенка
        setCurrentChild(state, action) {                   
            state.currentChild[action.payload?.id] = action.payload
        },                
        
        // Установить режим бронирования
        setBookMode(state, action) {                   
            state.bookMode = action.payload
            if(action.payload === 'admin') {
                state.bookMode = 'main'
            }
            
            // Установка информации в зависимости от режима бронирования
            switch(state.bookMode) {
                // Страницы "Главная"
                case 'main': 
                    state.sectionId = null
                    state.genderId = null
                    break
                // Страницы "Баскетбол М"
                case 'basketball_boys': 
                    state.sectionId = Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)
                    state.genderId = Number(process.env.REACT_APP_JOOMLA_MALE_GENDER)
                    break
                // Страницы "Баскетбол Ж"
                case 'basketball_girls': 
                    state.sectionId = Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)
                    state.genderId = Number(process.env.REACT_APP_JOOMLA_FEMALE_GENDER)
                    break
                // Страницы "Гимнастика Все"
                case 'gymnastics': 
                    state.sectionId = Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)
                    state.genderId = null
                    break        
                default: ;
            }            
        },
        
        // Установить список событий
        setEventList(state, action) {                   
            state.eventList = action.payload
        },
        // Установить список детей
        setChildrenList(state, action) {                   
            state.childrenList = action.payload
        },
        // Установить список возрастных групп
        setAgeList(state, action) {                   
            state.ageList = action.payload
        },
        
        // Установить имя для элемента списка возрастных групп
        setAgeItemName(state, action) {
            setAgeItemProperty(state, action.payload, 'name')            
        },        
    }
})

// Задать произвольное свойство propertyName для элемента списка возрастных групп
const setAgeItemProperty = (state, payload, propertyName) => {   
    return Object.assign(
        state, 
        {
            ...state,
            ageList: state.ageList.map(
                (age, i) => (age.attributes?.id === payload.id) 
                    ? {...age, 
                        attributes: {
                            ...age.attributes,
                            [propertyName]: payload.value
                        }
                    }
                    : age
            ),            
        }
    )
}

// Экспорт создателей действий
export const { 
    setHallId, setSectionId, setGenderId, setCurrentEvent, setEventList, 
    setChildrenList, setAgeList, setBookMode, setCurrentChild, setAgeItemName 
} = calendarSlice.actions
// Экспорт редьюсера
export default calendarSlice.reducer;

//THUNK CREATORS

// Получение списка событий
export const getEvents = (makeLoading = true) => {    
    return (dispatch, getState) => {
        if(makeLoading) {
            // Установить состояние загрузки в "Загружается"
            dispatch(setLoading('calendar'))
        }

        // Режим бронирования
        const bookMode = getState().calendarStore.bookMode
        // Айди зала
        const hallId = getState().calendarStore.hallId
        // Айди секции
        const sectionId = bookMode === 'main' ? null : getState().calendarStore.sectionId
        // Айди пола
        const genderId = bookMode === 'main' ? null : getState().calendarStore.genderId
        
        // Получение данных через API календаря
        calendarAPI.getEvents(hallId, sectionId, genderId).then((response) => {                        
            // Установить полученную информацию
            dispatch(setEventList(response))
            if(makeLoading) {
                // Установить состояние загрузки в "Загружено"
                dispatch(setLoaded('calendar'))
            }
        })
    }
}

// Получить событие
export const getEvent = (event_id) => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('event'))
        
        // Получение данных через API календаря
        calendarAPI.getEvent(event_id).then((response) => {                        
            // Установить текущее событие
            dispatch(setCurrentEvent(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('event'))            
        })
    }
}

// Забронировать событие
export const bookEvent = (data) => {    
    return (dispatch) => {       
        // Получение данных через API календаря
        calendarAPI.bookEvent(data).then((response) => {                        
            // Получение списка событий
            dispatch(getEvents())
        })
    }
}

// Обновить событие
export const updateEvent = (event_id, data) => {    
    return (dispatch) => {               
        // Получение данных через API календаря
        calendarAPI.updateEvent(event_id, data).then((response) => {                        
            // Установить текущее событие
            dispatch(setCurrentEvent(response))
            // Получение списка событий
            dispatch(getEvents(false))
        })
    }
}

// Удалить событие
export const deleteEvent = (event_id) => {    
    return (dispatch) => {               
        // Получение данных через API календаря
        calendarAPI.deleteEvent(event_id).then((response) => {                        
            // Получение списка событий
            dispatch(getEvents())
        })
    }
}

// Получение списка детей
export const getChildren = (makeLoading = true) => {    
    return (dispatch, getState) => {
        if(makeLoading) {
            // Установить состояние загрузки в "Загружается"
            dispatch(setLoading('child'))
        }

        // Айди зала
        const hallId = getState().calendarStore.hallId
        // Айди секции
        const sectionId = getState().calendarStore.sectionId
        // Айди пола
        const genderId = getState().calendarStore.genderId
        
        // Получение данных через API календаря
        calendarAPI.getChildren(hallId, sectionId, genderId).then((response) => {            
            // Установить полученную информацию
            dispatch(setChildrenList(response))
            if(makeLoading) {
                // Установить состояние загрузки в "Загружено"
                dispatch(setLoaded('child'))
            }
        })
    }
}

// Забронировать посещение ребенка
export const bookChild = (data) => {    
    return (dispatch) => {       
        // Получение данных через API календаря
        calendarAPI.bookChild(data).then((response) => {                        
            // Получение списка детей
            dispatch(getChildren())
        })
    }
}

// Удалить посещение ребенка
export const deleteChild = (child_id) => {    
    return (dispatch) => {               
        // Получение данных через API календаря
        calendarAPI.deleteChild(child_id).then((response) => {                        
            // Получение списка детей
            dispatch(getChildren())
        })
    }
}

// Обновить посещение ребенка
export const updateChild = (child_id, data) => {    
    return (dispatch) => {              
        // Получение данных через API календаря
        calendarAPI.updateChild(child_id, data).then((response) => {
            // Установить текущего ребенка
            dispatch(setCurrentChild(response))            
            // Получение списка детей
            dispatch(getChildren(false))          
        })
    }
}

// Получение списка возрастных групп
export const getAges = () => {    
    return (dispatch, getState) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('age'))
        
        // Получение данных через API календаря
        calendarAPI.getAges().then((response) => {            
            // Установить полученную информацию
            dispatch(setAgeList(response))                      
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('age'))
        })
    }
}

// Добавить элемент списка возрастных групп
export const addAge = (data) => {    
    return (dispatch) => {       
        // Получение данных через API календаря
        calendarAPI.addAge(data).then((response) => {                        
            // Получение списка возрастных групп
            dispatch(getAges())
        })
    }
}

// Обновить элемент списка возрастных групп
export const updateAge = (age_id, data) => {    
    return (dispatch) => {               
        // Получение данных через API календаря
        calendarAPI.updateAge(age_id, data).then((response) => {                              
            // Получение списка возрастных групп
            dispatch(getAges())
        })
    }
}

// Удалить элемент списка возрастных групп
export const deleteAge = (age_id) => {    
    return (dispatch) => {               
        // Получение данных через API календаря
        calendarAPI.deleteAge(age_id).then((response) => {                              
            // Получение списка возрастных групп
            dispatch(getAges())
        })
    }
}