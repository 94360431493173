// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { coachesAPI } from '../../api/endpoints/coachesAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Тренеры
    coaches: [],
}

// Слайс тренеров
const coachesSlice = createSlice({
    name: 'coaches',
    initialState,
    reducers: {
        // Установить список тренеров
        setCoaches(state, action) {                   
            state.coaches = action.payload
        },        
    }
})

// Экспорт создателей действий
export const { setCoaches } = coachesSlice.actions;
// Экспорт редьюсера
export default coachesSlice.reducer;

//THUNK CREATORS

// Получение списка тренеров
export const getCoaches = () => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('coach'))
                        
        // Получение данных через API тренеров
        coachesAPI.getCoaches().then((response) => {                        
            // Установить полученную информацию
            dispatch(setCoaches(response))                      
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('coach'))
        })
    }
}