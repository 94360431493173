// Node
import { createSlice } from '@reduxjs/toolkit'

// Начальное состояние
const initialState = {
    // Маркер открытия снекбара
    snackOpen: false,
    // Сообщение снекбара
    snackMessage: '',
    // Статус снекбара
    snackStatus: 'success',
}

// Слайс слайдеров
const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        // Установить маркер открытия снекбара
        setSnackOpen(state, action) {                   
            state.snackOpen = action.payload
        },
        // Установить сообщение снекбара
        setSnackMessage(state, action) {                   
            state.snackMessage = action.payload
        },
        // Установить статус снекбара
        setSnackStatus(state, action) {                   
            state.snackStatus = action.payload
        },
    }
})

// Экспорт создателей действий
export const { setSnackOpen, setSnackMessage, setSnackStatus } = messagesSlice.actions;
// Экспорт редьюсера
export default messagesSlice.reducer;