import React from 'react'
// Node
import {
    Box,
    Checkbox,
    FormControlLabel,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'

/*
 * Компонент "Поле формы "Чекбокс""
 */

// Экспорт компонента
export const CheckBoxFormField = (props) => {
    // Преобразование пропсов в локальные константы
    const { formik, fieldKey, fieldProps, changedFields, setChangedFields } = props

    const [checked, setChecked] = React.useState(
        Object.prototype.hasOwnProperty.call(formik.initialValues, fieldKey)
            ? formik.initialValues[fieldKey]
            : false,
    )
    const handleCheckboxChange = (event) => {
        if (!changedFields.includes(fieldKey)) {
            setChangedFields([...changedFields, fieldKey])
        }
        setChecked(event.target.checked)
        fieldProps?.onChange && fieldProps?.onChange()
        formik.setFieldValue(fieldKey, event.target.checked ? true : undefined)
    }

    return (
        <FormControlLabel
            control={
                <ListItemButton dense>
                    <ListItemIcon sx={{ minWidth: { xs: 'unset' } }}>
                        <Checkbox
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `listItemText_${fieldKey}`,
                            }}
                            onChange={handleCheckboxChange}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={`listItemText_${fieldKey}`}
                        primary={fieldProps?.props?.label}
                    />
                </ListItemButton>
            }
            label={
                <Box sx={{ ml: 2 }}>{`${fieldProps?.label} ${
                    fieldProps?.required ? '*' : ''
                }`}</Box>
            }
            labelPlacement={'top'}
            sx={{ alignItems: 'start', mx: 0 }}
        />
    )
}

// Свойства по умолчанию
CheckBoxFormField.defaultProps = {}

// Экспорт компонента
export default CheckBoxFormField
