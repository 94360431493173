import React from 'react'
// Node
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
// Redux
import { getMenu } from '../../../../../redux/reducers/menu-reducer'
// Own
import { GetMenuItems } from './data/data.menu_items'

/*
 * Блок "Меню на главной странице"
 */

// Экспорт блока
export const MenuBlock = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentMenu, loadingMenuComplete, getMenu } = props
    
    // Хук эффекта
    React.useEffect(() => {        
        // Получить меню
        getMenu()
    }, [getMenu])    
    
    // Маркер открытия меню
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    
    // История браузера
    const history = useHistory()
    
    // Получение массива элементов меню 
    const menuItems = GetMenuItems({menu: currentMenu, history})
    
    // Изменить состояние(открыто/закрыто) меню
    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }        
        setIsDrawerOpen(isOpen)
    }
    
    return (
        <>
            {loadingMenuComplete
                ? <>
                    <IconButton                        
                        color={'inherit'}                        
                        onClick={toggleDrawer(true)}
                        edge={'start'}
                        sx={{                     
                            ...(isDrawerOpen && { display: 'none' }),
                            mr: 2,
                            alignSelf: 'center',
                            position: 'absolute',
                            right: {xs: 0, md: '20px', lg: '10px'},
                        }}
                    >
                        <MenuIcon fontSize={'large'} />
                    </IconButton>

                    <Drawer
                        anchor={'right'}
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        PaperProps={{
                            sx: {maxWidth: '80%'}
                        }}                        
                    >
                        <List>
                            {menuItems.map((menuItem) => (
                                <ListItem selected={menuItem?.selected} key={menuItem.name} disablePadding>
                                    <ListItemButton onClick={() => {menuItem?.onClick(); setIsDrawerOpen(false)}}>
                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                            { menuItem.icon }
                                        </ListItemIcon>
                                        <ListItemText primary={menuItem.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>          
                    </Drawer>
                </>
                : []
            }
        </>
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state) => (    
    {
        currentMenu: state.menuStore?.currentMenu,        
        loadingMenuComplete: state.loadStore.loadingComplete?.menu,    
    }
)

// Экспорт блока
export default connect(mapStateToProps, {getMenu})(MenuBlock)    