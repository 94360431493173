// Получить картинки слайдера
export const getSliderImages = (props) => {
    // Преобразование пропсов в локальные константы        
    const { slides } = props
    
    // Изображения слайдера
    const sliderImages = []
    
    if(slides?.length) {
        slides?.forEach(slide => {    
            sliderImages.push({
                label: slide?.attributes?.title,
                imgPath: slide?.attributes?.image,
            })
        })
    }
    
    return sliderImages    
}