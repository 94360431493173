import React from 'react'
// Node
import { connect } from 'react-redux'
import _ from 'lodash-contrib'
import { Backdrop, Button, CircularProgress, Typography } from '@mui/material'
import { GroupAdd as GroupAddIcon} from '@mui/icons-material'
// Components
import MaterialTable from '../../../../components/material/MaterialTable'
import MaterialDialog from '../../../../components/material/MaterialDialog'
import BaseForm from '../../../../components/common/BaseForm'
// Redux
import { addAge, updateAge, setAgeItemName, deleteAge } from '../../../../../redux/reducers/calendar-reducer'
// Own
import {getTableData} from './data/data.table_rows'
import { values, initialValues, valuesHideRule } from './data/data-fields-values'
import { getFields } from './fields/fields-structure'
import { getSignupSchema } from './fields/fields-schema'

/*
 * Компонент "Управление возрастными группами"
 */

// Экспорт компонента
export const AgeComponent = (props) => {
    // Преобразование пропсов в локальные константы
    const { ageList, addAge, updateAge, setAgeItemName, deleteAge, loadingAgeComplete } = props

    // Элементы меню выпадающего списка "Пол возрастной группы"
    const genderMenuItems = (currentGenderId, updatedItemId) => [1, 2, 3].map(item => (
        {
            name: Number(item) === Number(process.env.REACT_APP_JOOMLA_MALE_GENDER) ? 'M' :
                Number(item) === Number(process.env.REACT_APP_JOOMLA_FEMALE_GENDER) ? 'F' : 'U' ,
            icon: null,        
            selected: Number(currentGenderId) === Number(item),
            onClick: () => {                
                updateAge(updatedItemId, {gender_id: item})                        
            }
        }
    ))
    
    // Событие с дебаунсом
    const debouncedEvent = _.debounce((updatedItemId, nameValue) => {
        // Установить строку запроса в фильтре компаний    
        updateAge(updatedItemId, {name: nameValue})
    }, 1000)
    
    // Обработка события изменения названия возрастной группы
    const handleAgeNameChange = (updatedItemId, nameValue) => {
        // Вызов события с дебаунсом    
        debouncedEvent(updatedItemId, nameValue)     
        
    }
    
    // Установка локального значения открытия диалога удаления
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    // Установка объекта с информацией об удалении объекта
    const [deleteAgeId, setDeleteAgeId] = React.useState(null)     
    
    // Получение данных таблицы
    const tableData = getTableData({ 
        ageList, genderMenuItems, handleAgeNameChange, setAgeItemName,
        setShowDeleteModal, setDeleteAgeId
    })

    // Установка маркера показывать ли модальное окно
    const [showModal, setShowModal] = React.useState(false)
    
    // Получение данных о структуре полей
    const fields = getFields()
    
    // Получение схемы валидации данных
    const SignupSchema = getSignupSchema()
    
    // Обработка кнопки добавления возрастной группы
    const handleAddAgeButtonClick = () => {
        let sections = [            
            {name: 'Baloncesto', id: Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)}, 
            {name: 'Gimnasia', id: Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)},
        ]
        sections.forEach((section, index) => {                               
            if (values?.section_id?.options) {                              
                values.section_id.options[index] = { value: section.id, text: section?.name }
            }
        })            
        initialValues.section_id = Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)
        
        let genders = [
            {name: 'M', id: Number(process.env.REACT_APP_JOOMLA_MALE_GENDER)},
            {name: 'F', id: Number(process.env.REACT_APP_JOOMLA_FEMALE_GENDER)}, 
            {name: 'U', id: Number(process.env.REACT_APP_JOOMLA_UNI_GENDER)},
        ]
        genders.forEach((gender, index) => {                               
            if (values?.gender_id?.options) {                              
                values.gender_id.options[index] = { value: gender.id, text: gender?.name }
            }
        })            
        initialValues.gender_id = Number(process.env.REACT_APP_JOOMLA_MALE_GENDER)        
        
        // Открытие модального окна
        setShowModal(true)
    }
    
    // Обработка события отправки формы
    const handleSubmit = (data) => {   
        ///console.log('data', data)
        
        // Добавить элемент списка возрастных групп
        addAge(data)
        // Закрытие модального окна
        setShowModal(false)
    }
    
    return(     
        <>
            {loadingAgeComplete
                ? <>
                    <Button                        
                        variant={'outlined'}
                        disableElevation                            
                        endIcon={<GroupAddIcon />}
                        sx={{mb: 2}}
                        onClick={handleAddAgeButtonClick}
                    >
                        Agregar grupo de edad
                    </Button>                    
                    <MaterialTable 
                        rows={tableData.rows} 
                        headers={tableData.headers}             
                    />
                    <MaterialDialog title="Cancelar la gruppo"
                        content="De verdad quieres cancelar?"
                        showModal={showDeleteModal}
                        onApplyText={'Sí'}
                        onCloseText={'No'}
                        onApply={() => { 
                            setShowDeleteModal(false)                                                                  
                            deleteAge(deleteAgeId)                                                                    
                        }}
                        onClose={() => { setShowDeleteModal(false) }} 
                    />
                    <MaterialDialog 
                        title={'Agregando un grupo de edad'}                
                        content={
                            <>
                                <Typography>Detalles de creación</Typography>
                                <BaseForm
                                    validationSchema={SignupSchema}
                                    fields={fields}
                                    values={values}
                                    initialValues={initialValues}
                                    valuesHideRule={valuesHideRule}                                                             
                                    onSubmit={handleSubmit}
                                    buttons={
                                        <>
                                            <Button
                                                variant="outlined"
                                                type="submit"
                                                sx={{ mt: 2, mr: 1 }}                                                
                                            >
                                                Agregar
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                sx={{ mt: 2, mr: 1 }}
                                                onClick={ () => setShowModal(false) }
                                            >
                                                Cancelar
                                            </Button>
                                        </>
                                    }
                                />                        
                            </>
                        }
                        onClose={ () => setShowModal(false) }
                        showOnCloseButton={false}
                        showApplyButton={false}
                        showModal={showModal}
                    />                    
                </>
                : <Backdrop
                    sx={{ color: '#fff', zIndex: 2 }}
                    open={!loadingAgeComplete}                
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}

// Маппинг стейта в пропсы компонента
const mapStateToProps = (state) => (    
    {        
        loadingAgeComplete: state.loadStore.loadingComplete?.age,
    }
)

// Экспорт компонента
export default connect(mapStateToProps, 
    { addAge, updateAge, setAgeItemName, deleteAge }
)(AgeComponent)