import React from 'react'
// Node
import { useHistory } from 'react-router-dom'
// Layouts
import MainLayout from './app/layouts/MainLayout'
import CalendarLayout from './app/layouts/CalendarLayout'
// Own
import './App.css'

/*
 * Приложение "Баскетбольная школа"
 */

// Экспорт компонента
export const App = () => {
    // История браузера
    const history = useHistory()
    const fullURL = history.location.pathname+history.location.search    
    
    return (
        <>  
            {
                fullURL === `/${process.env.REACT_APP_JOOMLA_CALENDAR_URL}`
                ? <CalendarLayout view={'admin'} />
                : <MainLayout />
            }
        </>
    )
}

// Дефолтный экспорт компонента
export default App
