const theme = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {   
        orange: {
            main: '#e67e23',
        },
        green: {
            main: '#2dc26b',
        },
        violet: {
            main: '#843fa1',
        },
        grey: {
            main: '#909090',
        },        
    },    
}

export default theme;