// Node
import config from 'react-global-configuration';

/*
 * Настройки приложения "Баскетбольная школа"
 */

// Экспорт типа исходного axios без настроек
export const AxiosOriginalType = 'original';
// Экспорт типа экземпляра axios(настроенного)
export const AxiosInstanceType = 'instance';

// Глобальные настройки (общие для всех режимов)
config.set({
    // Настройки API сервера
    api: {
        baseURL: `${
            process.env.REACT_APP_JOOMLA_API_BASE_URL
        }/${
            process.env.REACT_APP_JOOMLA_API_BASE_PATH
        }`,       
        article: {
            //url: '/mock/article/1.json', type: AxiosOriginalType, // mock
            url: 'content/articles/{article_id}', type: AxiosInstanceType, // real data
        },
        slider: {
            //url: '/mock/slider/slider.json', type: AxiosOriginalType, // mock            
            url: 'slider', type: AxiosInstanceType, // real data
        },
        menu: {
            //url: '/mock/menu/items.json', type: AxiosOriginalType, // mock            
            url: 'menus/site/items', type: AxiosInstanceType, // real data
        },
        calendar: {
            //url: '/mock/calendar/items.json', type: AxiosOriginalType, // mock            
            url: 'calendar', type: AxiosInstanceType, // real data
        },
        hallCalendar: {
            //url: '/mock/calendar/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/calendar', type: AxiosInstanceType, // real data
        },
        sectionCalendar: {
            //url: '/mock/calendar/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/section/{section_id}/calendar', type: AxiosInstanceType, // real data
        },        
        genderCalendar: {
            //url: '/mock/calendar/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/section/{section_id}/gender/{gender_id}/calendar', type: AxiosInstanceType, // real data
        },
        calendarEvent: {            
            url: 'calendar/{event_id}', type: AxiosInstanceType, // real data
        },
        child: {
            //url: '/mock/child/items.json', type: AxiosOriginalType, // mock            
            url: 'calendar/child', type: AxiosInstanceType, // real data
        },
        hallChild: {
            //url: '/mock/child/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/calendar/child', type: AxiosInstanceType, // real data
        },
        sectionChild: {
            //url: '/mock/child/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/section/{section_id}/calendar/child', type: AxiosInstanceType, // real data
        },        
        genderChild: {
            //url: '/mock/child/items.json', type: AxiosOriginalType, // mock            
            url: 'hall/{hall_id}/section/{section_id}/gender/{gender_id}/calendar/child', type: AxiosInstanceType, // real data
        },        
        calendarChild: {            
            url: 'calendar/child/{child_id}', type: AxiosInstanceType, // real data
        },
        age: {
            //url: '/mock/age/items.json', type: AxiosOriginalType, // mock            
            url: 'calendar/age', type: AxiosInstanceType, // real data
        },
        ageItem: {
            url: 'calendar/age/{age_id}', type: AxiosInstanceType, // real data
        },        
        user: {
            //url: '/mock/user/{hash}.json', type: AxiosOriginalType, // mock            
            url: 'calendar/user/{hash}', type: AxiosInstanceType, // real data
        },
        coaches: {            
            url: 'banners', type: AxiosInstanceType, // real data
        },        
    }
}, { freeze: false })