// Node
import config from 'react-global-configuration'
// API
import axios from '../instance'

// Экспорт API тренеров
export const coachesAPI = {   
    getCoaches() {        
        return axios[config.get('api.coaches.type')]
            .get(config.get('api.coaches.url'))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
}