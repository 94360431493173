import React from 'react'
// Node
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material'

/*
 * Компонент "Поле формы "Выпадающий список""
 */

// Экспорт компонента
export const SelectFormField = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        formik,
        values,
        valuesHideRule,
        fieldKey,
        fieldProps,
        changedFields,
        setChangedFields,
        hiddenFields,
        setHiddenFields,
    } = props

    const [selectValue, setSelectValue] = React.useState(
        Object.prototype.hasOwnProperty.call(formik.initialValues, fieldKey)
            ? formik.initialValues[fieldKey]
            : values[fieldKey]?.options
                ? Object.keys(values[fieldKey].options)[0]
                : '',
    )

    const handleSelectChange = (event) => {
        if (!changedFields.includes(fieldKey)) {
            setChangedFields([...changedFields, fieldKey])
        }
        setSelectValue(event.target.value)
        formik.handleChange(event)
    }

    React.useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(valuesHideRule, fieldKey)) {
            const { rule, value, hiddenField } = valuesHideRule[fieldKey]

            if (eval(selectValue + rule + value)) {
                if (!hiddenFields.includes(hiddenField)) {
                    setHiddenFields([...hiddenFields, hiddenField])
                }
            } else {
                setHiddenFields(hiddenFields.filter((field) => field !== hiddenField))
            }
        }
    }, [selectValue])

    const selectOptions = []

    if (values[fieldKey]?.placeholder) {
        selectOptions.push(
            <MenuItem value="">
                <em>{values[fieldKey]?.placeholder}</em>
            </MenuItem>,
        )
    }
    values[fieldKey]?.options?.forEach((option) => {
        selectOptions.push(<MenuItem value={option?.value}>{option?.text}</MenuItem>)
    })

    return (
        <FormControl
            fullWidth={fieldProps?.fullWidth}
            sx={{
                display: hiddenFields && hiddenFields.includes(fieldKey) ? 'none' : 'block',
            }}
        >
            <InputLabel id={`label_${fieldKey}`}>{`${fieldProps?.label} ${
                fieldProps?.required ? '*' : ''
            }`}</InputLabel>
            <Select
                {...fieldProps}
                value={selectValue}
                onChange={handleSelectChange}
                labelId={`label_${fieldKey}`}
            >
                {selectOptions}
            </Select>
        </FormControl>
    )
}

// Свойства по умолчанию
SelectFormField.defaultProps = {}

// Экспорт компонента
export default SelectFormField
