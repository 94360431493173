// Node
import _ from 'lodash-contrib'
import { Button } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
// Components
import MaterialMenu from '../../../../../components/material/MaterialMenu'

// Экспорт данных таблицы
export const getTableData = (props) => {
    // Преобразование пропсов в локальные константы
    const { 
        currentEvent, extendedProps, setDeleteInfo, 
        setShowDeleteModal, statusMenuItems, currentChild 
    } = props
    
    // Заголовки таблицы
    const tableHeaders = {
        child_full_name:            {title: "Nombre completo del niño", main: true, align: 'left'},
        child_age:                  {title: "Edad del niño", main: true, align: 'left'},        
        hall_id:                    {title: "Número de gimnasio", main: true, align: 'left'},
        section_id:                 {title: "Sección", main: true, align: 'left'},        
        age_id:                     {title: "Grupo de edad", main: true, align: 'left'},
        status:                     {title: "Estado", main: true, align: 'left'},
        full_name:                  {title: "Nombre completo", main: true, align: 'left'},
        email:                      {title: "Dirección de correo electrónico", main: true, align: 'left'},
        phone:                      {title: "Número de teléfono", main: true, align: 'left'},
        cancel:                     {title: "Cancelar la reserva", main: true, align: 'left'},
    }
    
    // Массив строк таблицы
    const tableRows = [];
    // Если список посетителей - не пустой массив
    if(currentEvent?.attributes?.child) {
        currentEvent?.attributes?.child?.forEach((childItem) => {
            const clusterObject = { slaveRows: [] };
            const clusterSlaveRowsObject = {};                        
            // Проход по всем полям одного посетителя, которые станут строками таблицы
            for(const key in tableHeaders) {
                // Значение, присваиваемое ячейке таблицы
                let childItemValue = childItem[key]
                // Обработка поля для номера зала
                if (['hall_id'].includes(key)) {                    
                    childItemValue = currentEvent?.attributes?.hall_id        
                }                
                // Обработка поля для секции
                if (['section_id'].includes(key)) {
                    // Установка информации в зависимости от типа секции
                    switch(childItemValue) {
                        // Баскетбол
                        case Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION) : 
                            childItemValue = 'baloncesto'; break
                        // Гимнастика
                        case Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION) : 
                            childItemValue = 'gimnasia'; break
                        // Аренда
                        case Number(process.env.REACT_APP_JOOMLA_RENT_SECTION) : 
                            childItemValue = 'alquilar'; break                                             
                        default: ;
                    }                    
                }
                // Обработка поля для возрастной группы
                if (['age_id'].includes(key)) {                    
                    childItemValue = extendedProps?.age        
                }
                // Обработка поля изменения статуса бронирования
                if (['status'].includes(key)) {                    
                    childItemValue =
                        <>
                            <MaterialMenu
                                id={`status-child-menu-${childItem?.id}`}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                menuItems={
                                    statusMenuItems(
                                        currentChild[childItem?.id]?.attributes?.status_id,
                                        childItem?.id,
                                        'child'
                                    )
                                }
                                sx={{ float: 'left', pl: '5px'}}
                            >                                                                                
                                <Button                           
                                    aria-controls={`status-child-menu-${childItem?.id}`}
                                    aria-haspopup={true}
                                    variant={'outlined'}
                                    disableElevation                            
                                    endIcon={<KeyboardArrowDownIcon />}                                    
                                >
                                    {
                                        Number(currentChild[childItem?.id]?.attributes?.status_id) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
                                        ? 'reservado' : 'ocupado'
                                    }
                                </Button>                        
                            </MaterialMenu>                            
                        </>
                }                
                // Обработка поля отмены бронирования
                if (['cancel'].includes(key)) {                    
                    childItemValue = 
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setDeleteInfo({type: 'child', id: childItem?.id})
                                setShowDeleteModal(true)
                            }}                            
                        >
                            Cancelar reserva
                        </Button>
                }
                        
                // Если будет формироваться главная строка таблицы
                if(tableHeaders[key].main) {
                    clusterObject[key] = childItemValue
                }
                // Если будет формироваться второстепенная строка таблицы
                if(!tableHeaders[key].main) {
                    clusterSlaveRowsObject[key] = childItemValue
                }
            }
            // ID главной строки таблицы
            clusterObject.mainId = childItem?.cluster_id
            
            if(!_.isEmpty(clusterSlaveRowsObject)) {
                clusterObject.slaveRows?.push(clusterSlaveRowsObject);
            }
            // Формирование элемента массива строк таблицы
            tableRows.push(clusterObject);
        });
    }
    
    // Возврат сформированных данных таблицы
    return { headers: tableHeaders, rows: tableRows }
}  