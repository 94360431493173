import React from 'react'
// Node
import esLocale from 'date-fns/locale/es'
import { Box, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

/*
 * Компонент "Календарь" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialTimePicker = (props) => {
    // Преобразование пропсов в локальные константы
    const { onChange, time, sx, label, fullWidth, required, disabled, } = props

    const [value, setValue] = React.useState(time ? time : null)
    
    // Хук эффекта
    React.useEffect(() => {
        onChange && onChange(value)
    }, [])    

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
            <Box sx={sx}>                
                <MobileTimePicker
                    {...props}
                    label={label}
                    views={['hours', 'minutes']}
                    format="hh:mm"
                    value={value}
                    disabled={disabled}
                    onChange={(newValue) => {                       
                        // Дата, которая должна быть установлена
                        const settedValue = newValue

                        setValue(settedValue)
                        onChange && onChange(settedValue)
                    }}
                    renderInput={(params) => (
                        <TextField required={required} {...params} fullWidth={fullWidth} />
                    )}
                />                        
            </Box>
        </LocalizationProvider>
    )
}

// Свойства по умолчанию
MaterialTimePicker.defaultProps = {
    disabled: false,
}

// Экспорт компонента
export default MaterialTimePicker
