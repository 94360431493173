// Node
import config from 'react-global-configuration'
// API
import axios from '../instance'

// Экспорт API меню
export const menuAPI = {   
    getMenu() {        
        return axios[config.get('api.menu.type')]
            .get(config.get('api.menu.url'))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
}