// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { articlesAPI } from '../../api/endpoints/articlesAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Текущая статья
    currentArticle: null,
    // Айди текущей статьи
    currentArticleId: 1,
}

// Слайс статей
const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        // Установить текущую статью
        setCurrentArticle(state, action) {                   
            state.currentArticle = action.payload
        },
        // Установить айди текущей статьи
        setCurrentArticleId(state, action) {                   
            state.currentArticleId = action.payload
        },        
    }
})

// Экспорт создателей действий
export const { setCurrentArticle, setCurrentArticleId } = articleSlice.actions;
// Экспорт редьюсера
export default articleSlice.reducer;

//THUNK CREATORS

// Получение статьи
export const getArticle = (article_id = 1) => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('article'))
                        
        // Получение данных через API статей
        articlesAPI.getArticle(article_id).then((response) => {                        
            // Установить полученную информацию
            dispatch(setCurrentArticle(response))                      
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('article'))
        })
    }
}