// Node
import * as Yup from 'yup'

export const getSignupSchema = () => {
    const SignupSchema = {
        section_id: Yup.number()        
            .required('Requerido para llenar'),        
        gender_id: Yup.number()        
            .required('Requerido para llenar'),
        name: Yup.string()
            .min(1, 'El número de caracteres debe ser al menos 1')
            .max(99, 'El número de caracteres no debe ser superior a 99')
            .required('Requerido para llenar')    
    }
        
    return SignupSchema
}
