import React from 'react'
// Node
import { TextField } from '@mui/material'

/*
 * Компонент "Поле формы "Текстовое поле ввода""
 */

// Экспорт компонента
export const TextFormField = (props) => {
    // Преобразование пропсов в локальные константы
    const { formik, fieldKey, fieldProps, changedFields, setChangedFields, hiddenFields } = props

    const [textValue, setTextValue] = React.useState(
        Object.prototype.hasOwnProperty.call(formik.initialValues, fieldKey)
            ? formik.initialValues[fieldKey]
            : '',
    )
    
    const handleTextChange = (event) => {
        if (!changedFields.includes(fieldKey)) {
            setChangedFields([...changedFields, fieldKey])
        }
        setTextValue(event?.target?.value)                
        formik.handleChange(event)
    }

    return (
        <TextField
            {...fieldProps}
            value={textValue}
            sx={{
                display: hiddenFields && hiddenFields.includes(fieldKey) ? 'none' : 'block',
            }}
            onChange={handleTextChange}
        />
    )
}

// Свойства по умолчанию
TextFormField.defaultProps = {}

// Экспорт компонента
export default TextFormField
