// Node
import _ from 'lodash-contrib'
import { Button, TextField } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
// Components
import MaterialMenu from '../../../../../components/material/MaterialMenu'

// Экспорт данных таблицы
export const getTableData = (props) => {
    // Преобразование пропсов в локальные константы
    const { 
        ageList, genderMenuItems, handleAgeNameChange, setAgeItemName,
        setShowDeleteModal, setDeleteAgeId } = props
    
    // Заголовки таблицы
    const tableHeaders = {                    
        section_id:         {title: "Sección", main: true, align: 'left'},        
        gender_id:          {title: "Gender", main: true, align: 'left'},
        name:               {title: "Nombre del grupo", main: true, align: 'left'},        
        cancel:             {title: "Cancelar la gruppo", main: true, align: 'left'},
    }
    
    // Массив строк таблицы
    const tableRows = [];
    // Если список возрастных групп - не пустой массив
    if(ageList) {
        ageList?.forEach((childItem, index) => {
            const clusterObject = { slaveRows: [] }
            const clusterSlaveRowsObject = {}      
            const childItemId = childItem?.attributes?.id
            // Проход по всем полям одного посетителя, которые станут строками таблицы
            for(const key in tableHeaders) {
                // Значение, присваиваемое ячейке таблицы
                let childItemValue = childItem?.attributes[key]                         
                // Обработка поля для секции
                if (['section_id'].includes(key)) {
                    // Установка информации в зависимости от типа секции
                    switch(childItemValue) {
                        // Баскетбол
                        case Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION) : 
                            childItemValue = 'baloncesto'; break
                        // Гимнастика
                        case Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION) : 
                            childItemValue = 'gimnasia'; break
                        // Аренда
                        case Number(process.env.REACT_APP_JOOMLA_RENT_SECTION) : 
                            childItemValue = 'alquilar'; break                                             
                        default: ;
                    }                    
                }
                // Обработка поля изменения пола
                if (['gender_id'].includes(key)) {                                                     
                    childItemValue =
                        <>
                            <MaterialMenu
                                id={`gender-menu-${childItemId}`}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                menuItems={
                                    genderMenuItems(
                                        ageList[index]?.attributes?.gender_id,
                                        childItemId,
                                        'child'
                                    )
                                }
                                sx={{ float: 'left', pl: '5px'}}
                            >                                                                                
                                <Button                           
                                    aria-controls={`gender-menu-menu-${childItemId}`}
                                    aria-haspopup={true}
                                    variant={'outlined'}
                                    disableElevation                            
                                    endIcon={<KeyboardArrowDownIcon />}                                    
                                >
                                    {
                                        Number(ageList[index]?.attributes?.gender_id) === Number(process.env.REACT_APP_JOOMLA_MALE_GENDER) ? 'M' :
                                            Number(ageList[index]?.attributes?.gender_id) === Number(process.env.REACT_APP_JOOMLA_FEMALE_GENDER) ? 'F' : 'U'
                                    }
                                </Button>                        
                            </MaterialMenu>                            
                        </>
                }
                // Обработка поля изменения имени
                if (['name'].includes(key)) {                                                     
                    childItemValue =
                        <TextField 
                            variant="outlined" 
                            value={ageList[index]?.attributes?.name} 
                            onChange={(event) => {
                                const nameValue = event.target.value                            
                                setAgeItemName({id: childItemId, value: nameValue})                            
                                handleAgeNameChange(childItemId, nameValue)
                            }}
                        />                        
                }                
                               
                // Обработка поля отмены бронирования
                if (['cancel'].includes(key)) {                    
                    childItemValue = 
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setDeleteAgeId(childItemId)
                                setShowDeleteModal(true)                             
                            }}                            
                        >
                            Cancelar la gruppo
                        </Button>
                }
                        
                // Если будет формироваться главная строка таблицы
                if(tableHeaders[key].main) {
                    clusterObject[key] = childItemValue
                }
                // Если будет формироваться второстепенная строка таблицы
                if(!tableHeaders[key].main) {
                    clusterSlaveRowsObject[key] = childItemValue
                }
            }
            // ID главной строки таблицы
            clusterObject.mainId = childItem?.attributes?.id
            
            if(!_.isEmpty(clusterSlaveRowsObject)) {
                clusterObject.slaveRows?.push(clusterSlaveRowsObject);
            }
            // Формирование элемента массива строк таблицы
            tableRows.push(clusterObject);
        });
    }
    
    // Возврат сформированных данных таблицы
    return { headers: tableHeaders, rows: tableRows }
}  