import React from 'react'
// Node
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper } from '@mui/material'
// Own
import MaterialRow from './components/MaterialRow'

/*
 * Компонент "Таблица" (обертка над MaterialUI)
 */

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// Кросс-платформенная сортировка, поддерживающая IE11
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

// Экспорт компонента
export const MaterialTable = (props) => {
    // Преобразование пропсов в локальные константы
    const { rows, headers, slaveRowTitle, onClick } = props
    
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property)
    }    
    
    // Массив заголовков главных строк таблицы
    const headersContent = []    
    for(const key in headers) {
        if(headers[key]?.main) {            
            headersContent.push(                
                <TableCell align={'left'} sx={{ px: '5px' }} key={`tableCellMainHeader_${key}`}>
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'asc'}
                      onClick={createSortHandler(key)}
                    >
                        {headers[key]?.title}
                    </TableSortLabel>
                </TableCell>                
            )
        }
    }
    
    return (  
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    {headersContent.length
                        ? <TableRow>
                            <TableCell sortDirection={'desc'}/>
                            { headersContent }                        
                        </TableRow>
                        : []
                    }
                </TableHead>
                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                     ?.map((row, index) => (
                        <MaterialRow 
                            key={`materialRow_${index}`} 
                            row={row} 
                            headers={headers}
                            slaveRowTitle={slaveRowTitle}
                            onClick={onClick}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

// Свойства по умолчанию
MaterialTable.defaultProps = { }

// Экспорт компонента
export default MaterialTable