// Node
import {makeStyles} from '@mui/styles';

// Конструктор стилей
const useStyleMui = () => {    
    const contentBottomPadding = 20
    const footerHeight = 70
    const footerBorderHeight = 4
    
    return makeStyles(() => ({
        content: {                        
            paddingBottom: `${contentBottomPadding}px`,            
        },        
        footer: {
            backgroundColor: '#333',
            //borderTop: `${footerBorderHeight}px solid #d73f2e`,            
            color: '#fff',
            height: `${footerHeight + footerBorderHeight}px`,            
            marginTop: 'auto !important',            
            zIndex: 10,            
        },
    }))();   
}

// Экспорт конструктора стилей
export default useStyleMui;
