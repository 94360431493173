import React from 'react'
//Node
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

/*
 * Компонент "Строка таблицы" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialRow = (props) => {
    // Преобразование пропсов в локальные константы
    const { row, headers, slaveRowTitle, onClick } = props    
    const [open, setOpen] = React.useState(false)
    
    // Массив заголовков второстепенных строк таблицы
    const headersContent = []
    for(const key in headers) {
        if(!headers[key]?.main) {            
            headersContent.push(<TableCell key={`tableCellSlaveHeader_${key}`}>{headers[key]?.title}</TableCell>)
        }
    }
    // Массив главных строк таблицы
    const mainRowContent = []    
    for(const key in row) {      
        let rowValue = row[key] ? row[key] : '-'    
        if(headers[key]?.digitSeparator) {
            rowValue = rowValue.toLocaleString()
        }
        if(!['mainId', 'slaveRows'].includes(key)) {
            mainRowContent.push(
                <TableCell
                    sx={{...headers[key]?.cellSx, px: '5px'}}                    
                    align={headers[key]?.align} 
                    onClick={() => onClick && onClick(row)} 
                    key={`tableCellMainRow_${key}`}>{rowValue}
                </TableCell>
            )        
        }
    }
    // Массив второстепенных строк таблицы
    const slaveRowContent = []        
    row?.slaveRows?.forEach((item, index) => {
        const tableCellContent = []
        for(const key in item) {           
            tableCellContent.push(<TableCell key={`tableCellSlaveRow_${index}_${key}`}>{item[key]}</TableCell>)
        }
        slaveRowContent.push(<TableRow key={`tableRowSlaveRow_${index}`}>{tableCellContent}</TableRow>)
    })
    
    return (  
        <>
            <TableRow hover sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }}>                
                <TableCell>
                    {slaveRowContent.length 
                        ? <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton> 
                        : []
                    }
                </TableCell>                
                { mainRowContent }
            </TableRow>
            {slaveRowContent.length 
                ? <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    { slaveRowTitle }
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            { headersContent }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>                                    
                                        { slaveRowContent }                                        
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow> 
                : []
            }
        </>
    )
}

// Свойства по умолчанию
MaterialRow.defaultProps = { 
    onClick: null
}

// Экспорт компонента
export default MaterialRow