import React from 'react'
// Node
import moment from 'moment'
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
// Components
import MaterialDialog from '../../../../components/material/MaterialDialog'
import BaseForm from '../../../../components/common/BaseForm'
// Own
import useStyleMui from './styles/style.mui'
import { values, initialValues, valuesHideRule, submitButtonDisableFields } from './data/data-fields-values'
import { getFields } from './fields/fields-structure'
import { getSignupSchema } from './fields/fields-schema'

/*
 * Компонент "Бронирование для календаря"
 */

// Экспорт компонента
export const BookComponent = (props) => {
    // Преобразование пропсов в локальные константы
    const { sectionId, bookFields, ageList, bookEvent, bookChild, 
        snackOpen, snackMessage, snackStatus,
        setSnackOpen, setSnackMessage, setSnackStatus, bookMode, view 
    } = props 
    
    // Установка маркера показывать ли модальное окно
    const [showModal, setShowModal] = React.useState(false)
    
    // Стиль шаблона
    const styleMui = useStyleMui() 
    
    // Получение данных о структуре полей
    const fields = getFields(view, bookMode, sectionId)
    // Установка недоступности поля для редактирования
    if(['main'].includes(bookMode)) {
        if(fields['hall_id']) {
            fields['hall_id'].disabled = true
        }
    }
    if(['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode) ||
       (['main'].includes(bookMode) && ['admin'].includes(view))) {            
        if(fields['section_id']) {
            fields['section_id'].disabled = true
        }        
    }
    //fields['book_date'].disabled = bookMode === 'main' ? true : false
    //fields['book_start_time'].disabled = bookMode === 'main' ? true : false
    //fields['book_end_time'].disabled = bookMode === 'main' ? true : false

    let submitButtonDisableFieldsClone = Array.from(submitButtonDisableFields)
    if(['admin'].includes(view)) {
        submitButtonDisableFieldsClone = []
    }
    else {        
        submitButtonDisableFieldsClone = ['personal']
    }
    
    // Получение схемы валидации данных
    const SignupSchema = getSignupSchema(view, bookMode, sectionId)
       
    // Установка маркера активности кнопки отправки формы
    const [submitButtonDisable, setSubmitButtonDisable] = React.useState(true)

    // Метод переключения маркера активности кнопки отправки формы
    const toggleSubmitButtonDisable = () => {
        setSubmitButtonDisable(!submitButtonDisable)
    }    
    
    const handleBookButtonClick = () => {
        // Закрыть снекбар    
        setSnackOpen(false)
        
        // Установка ошибки формы отправки данных
        let bookError = null
        
        // Инициализация объекта начальных значений полей
        if(['main'].includes(bookMode)) {
            initialValues.hall_id = bookFields.hall_id
            initialValues.book_date = bookFields.book_date
            initialValues.book_start_time = bookFields.book_start_time
            initialValues.book_end_time = bookFields.book_end_time
            
            // Текущая дата
            const current_date = new Date()            
            const bookDateTimestamp = moment(initialValues.book_date).format("X")
            const currentDateTimestamp = moment(current_date).format("X")
            if(bookDateTimestamp < currentDateTimestamp) {
                bookError = 'El tiempo de reserva no debe ser inferior a la hora actual!'
            } 
        }
        if(
            (['main'].includes(bookMode) && ['admin'].includes(view)) ||
            ['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode)
        ) {
            let sections = ['admin'].includes(view)
                ? [{name: 'Alquilar', id: Number(process.env.REACT_APP_JOOMLA_RENT_SECTION)}]
                : [];
            sections = sections.concat([
                {name: 'Baloncesto', id: Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)}, 
                {name: 'Gimnasia', id: Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)}
            ])
            sections.forEach((section, index) => {                               
                if (values?.section_id?.options) {                              
                    values.section_id.options[index] = { value: section.id, text: section?.name }
                }
            })            
            initialValues.section_id = sectionId === null ? Number(process.env.REACT_APP_JOOMLA_RENT_SECTION) : Number(sectionId)
            
            if (values?.age_id?.options) {
                values.age_id.options = []
                ageList.forEach((age, index) => {                                                   
                    // Если возрастная группа соответствует текущей секции                
                    if(Number(age?.attributes?.section_id) === Number(sectionId)) {
                        // Если пол возрастной группы соответствует текущей секции                                         
                        if(
                            (['main'].includes(bookMode) && ['admin'].includes(view)) || ['gymnastics'].includes(bookMode) ||
                            (((['main'].includes(bookMode) && ['admin'].includes(view)) || ['basketball_boys'].includes(bookMode)) && Number(age?.attributes?.gender_id) === Number(process.env.REACT_APP_JOOMLA_MALE_GENDER)) ||
                            (((['main'].includes(bookMode) && ['admin'].includes(view)) || ['basketball_girls'].includes(bookMode)) && Number(age?.attributes?.gender_id) === Number(process.env.REACT_APP_JOOMLA_FEMALE_GENDER))
                        ) {                                                                        
                            values.age_id.options[index] = {
                                value: age?.attributes?.id, 
                                text: `${age?.attributes?.gender} - ${age?.attributes?.name}` 
                            }                            
                        }
                    }                    
                })
            }                       
        }
        
        if(!bookError) {
            if(submitButtonDisableFieldsClone.length) {
                // Сделать кнопку отправки формы неактивной
                setSubmitButtonDisable(true)
            }
            else {
                // Сделать кнопку отправки формы активной
                setSubmitButtonDisable(false)
            }
            // Открытие модального окна
            setShowModal(true)
        }
        else {
            setSnackStatus('error')        
            setSnackMessage(bookError)
            // Открыть снекбар
            setSnackOpen(true)            
        }
    }
    
    // Обработка события отправки формы
    const handleSubmit = (data) => {
        try {    
            // Подготовка данных бронирования к отправке
            if(['main'].includes(bookMode)) {
                data.start_time = `${data?.book_date}T${data?.book_start_time}:00`
                data.end_time = `${data?.book_date}T${data?.book_end_time}:00`
                
                if(!['admin'].includes(view)) {
                    // Секция "Аренда"
                    data.section_id = Number(process.env.REACT_APP_JOOMLA_RENT_SECTION)
                }
                
                if(!sectionId) {
                    // Возраст "0"
                    data.age_id = Number(process.env.REACT_APP_JOOMLA_RENT_AGE)
                }
                
                if(['admin'].includes(view)) { 
                    if(sectionId !== null) {
                        // Персональные данные            
                        data.full_name = 'admin'
                        data.email = '***'
                        data.phone = '*****'
                    }
                }
            }
            if(['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode)) {
               data.hall_id = 0 
            }

            // Статус "Забронировано"
            data.status_id = Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS)
            
            if(['admin'].includes(view)) {                 
                // Статус "Занято"
                data.status_id = Number(process.env.REACT_APP_JOOMLA_BUSY_STATUS)                
            }                
            
            delete data['book_date']
            delete data['book_start_time']
            delete data['book_end_time']  
            
            // Отправка данных
            if(['main'].includes(bookMode)) {                
                bookEvent(data)
            }
            if(['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode)) {                                   
                bookChild(data)
            }

            // Закрытие модального окна
            setShowModal(false)
            
            // Сообщение и статус снекбара
            setSnackMessage('<p>Reserva completada con éxito!</p><p>La información detallada ha sido enviada a su correo electrónico.</p>')            
            setSnackStatus('success')            
        } catch(error){
            // Сообщение и статус снекбара        
            setSnackStatus('error')        
            setSnackMessage('<p>Hubo un error de reserva...</p><p>Disculparse!</p><p>Intente enviar los datos más tarde.</p>')
        }
        
        // Открыть снекбар
        setSnackOpen(true)
        console.log('data', data)
    }    
    
    // Установка массива имен полей, которые были скрыты
    const [hiddenFields, setHiddenFields] = React.useState([])        

    // Обработка события закрытия снекбара
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        
        // Закрыть снекбар
        setSnackOpen(false)
    }
    
    return(     
        <>
            <Box className={styleMui.wrap} onClick={handleBookButtonClick}>
                <Box className={styleMui.linkCover}></Box>
                <Box className={styleMui.linkWrap}>
                    Libro seleccionado
                </Box>
            </Box>
            
            <MaterialDialog 
                title={'Libro seleccionado'}                
                content={
                    <>
                        <Typography>Detalles de la reserva</Typography>
                        <BaseForm
                            validationSchema={SignupSchema}
                            fields={fields}
                            values={values}
                            initialValues={initialValues}
                            valuesHideRule={valuesHideRule}                            
                            hiddenFields={hiddenFields}
                            setHiddenFields={setHiddenFields}
                            submitButtonDisableFields={submitButtonDisableFieldsClone}
                            toggleSubmitButtonDisable={toggleSubmitButtonDisable}
                            onSubmit={handleSubmit}
                            buttons={
                                <>
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        sx={{ mt: 2, mr: 1 }}
                                        disabled={submitButtonDisable}
                                    >
                                        Listo
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ mt: 2, mr: 1 }}
                                        onClick={ () => setShowModal(false) }
                                    >
                                        Cancelar
                                    </Button>
                                </>
                            }
                        />                        
                    </>
                }
                onClose={ () => setShowModal(false) }
                showOnCloseButton={false}
                showApplyButton={false}
                showModal={showModal}
            />

            <Snackbar
                open={snackOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleSnackClose}
            >
                <Alert
                    variant={'filled'}
                    severity={snackStatus}
                    sx={{ 
                        width: '100%',                    
                        backgroundColor: snackStatus === 'success' ? '#2dc26b' : '#e67e23'
                    }}
                    onClose={handleSnackClose}
                >
                    <span 
                        dangerouslySetInnerHTML={
                            { __html: snackMessage }
                        } 
                    />                    
                </Alert>
            </Snackbar>
        </>
    )
}

// Экспорт компонента
export default BookComponent