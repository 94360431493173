import React from 'react'
// Node
import ruLocale from 'date-fns/locale/es'
import { Box, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

/*
 * Компонент "Календарь" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialDatePicker = (props) => {
    // Преобразование пропсов в локальные константы
    const { onChange, date, sx, fullWidth, required, disabled } = props

    const [value, setValue] = React.useState(date ? date : null)
    
    // Хук эффекта
    React.useEffect(() => {
        onChange && onChange(value)
    }, [])    

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <Box sx={sx}>
                <DatePicker
                    {...props}
                    mask={'__/__/____'}
                    value={value}
                    disabled={disabled}
                    onChange={(newValue) => {        
                        // Дата, которая должна быть установлена
                        const settedValue = newValue

                        setValue(settedValue)
                        onChange && onChange(settedValue)
                    }}
                    renderInput={(params) => (
                        <TextField required={required} {...params} fullWidth={fullWidth} />
                    )}
                />
            </Box>
        </LocalizationProvider>
    )
}

// Свойства по умолчанию
MaterialDatePicker.defaultProps = {
    disabled: false,
}

// Экспорт компонента
export default MaterialDatePicker
