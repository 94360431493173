// Node
import config from 'react-global-configuration'
// API
import axios from '../instance'

// Экспорт API календаря
export const calendarAPI = {   
    getEvents(hall_id = null, section_id = null, gender_id = null) {
        let axiosType = 'calendar'
                
        if(hall_id && section_id && gender_id) {
            axiosType = 'genderCalendar'
        } else
        if(hall_id && section_id) {
            axiosType = 'sectionCalendar'
        } else  
        if(hall_id) {
            axiosType = 'hallCalendar'
        }
        
        return axios[config.get(`api.${axiosType}.type`)]
            .get(
                config.get(`api.${axiosType}.url`)
                    .replace('{hall_id}', hall_id)
                    .replace('{section_id}', section_id)
                    .replace('{gender_id}', gender_id)
            )
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    getEvent(event_id) {        
        return axios[config.get('api.calendarEvent.type')]
            .get(config.get('api.calendarEvent.url').replace('{event_id}', event_id))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    bookEvent(data) {        
        return axios[config.get('api.calendar.type')]
            .post(config.get('api.calendar.url'), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    updateEvent(event_id, data) {        
        return axios[config.get('api.calendarEvent.type')]
            .patch(config.get('api.calendarEvent.url').replace('{event_id}', event_id), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    deleteEvent(event_id) {        
        return axios[config.get('api.calendarEvent.type')]
            .delete(config.get('api.calendarEvent.url').replace('{event_id}', event_id))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    getChildren(hall_id = null, section_id = null, gender_id = null) {
        let axiosType = 'child'
                
        if(hall_id && section_id && gender_id) {
            axiosType = 'genderChild'
        } else
        if(hall_id && section_id) {
            axiosType = 'sectionChild'
        } else  
        if(hall_id) {
            axiosType = 'hallChild'
        }
        
        return axios[config.get(`api.${axiosType}.type`)]
            .get(
                config.get(`api.${axiosType}.url`)
                    .replace('{hall_id}', hall_id)
                    .replace('{section_id}', section_id)
                    .replace('{gender_id}', gender_id)
            )
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    bookChild(data) {        
        return axios[config.get('api.child.type')]
            .post(config.get('api.child.url'), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    updateChild(child_id, data) {        
        return axios[config.get('api.calendarChild.type')]
            .patch(config.get('api.calendarChild.url').replace('{child_id}', child_id), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    deleteChild(child_id) {        
        return axios[config.get('api.calendarChild.type')]
            .delete(config.get('api.calendarChild.url').replace('{child_id}', child_id))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    getAges() {        
        return axios[config.get('api.age.type')]
            .get(config.get('api.age.url'))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    addAge(data) {        
        return axios[config.get('api.age.type')]
            .post(config.get('api.age.url'), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    updateAge(age_id, data) {        
        return axios[config.get('api.ageItem.type')]
            .patch(config.get('api.ageItem.url').replace('{age_id}', age_id), data)
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },
    deleteAge(age_id) {        
        return axios[config.get('api.ageItem.type')]
            .delete(config.get('api.ageItem.url').replace('{age_id}', age_id))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
    getUser(hash = 1) {        
        return axios[config.get('api.user.type')]
            .get(config.get('api.user.url').replace('{hash}', hash))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
}