// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { menuAPI } from '../../api/endpoints/menuAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Текущее меню
    currentMenu: null,
    // Маппер "меню - статья"
    menuToArticleMapper: null,
}

// Слайс меню
const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        // Установить текущее меню
        setCurrentMenu(state, action) {                   
            state.currentMenu = action.payload
        },
        // Установить текущий маппер
        setMenuToArticleMapper(state, action) {                   
            state.menuToArticleMapper = action.payload
        },        
    }
})

// Экспорт создателей действий
export const { setCurrentMenu, setMenuToArticleMapper } = menuSlice.actions;
// Экспорт редьюсера
export default menuSlice.reducer;

//THUNK CREATORS

// Получение меню
export const getMenu = () => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('menu'))
                        
        // Получение данных через API меню
        menuAPI.getMenu().then((response) => {                        
            // Установить текущее меню
            dispatch(setCurrentMenu(response))
            // Установить текущий маппер        
            dispatch(setMenuToArticleMapper(
                getMenuToArticleMapper(response)
            ))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('menu'))
        })
    }
}


// Сформировать маппер "меню - статья"
const getMenuToArticleMapper = (menu) => {
    let menuToArticleMapper = {}
    
    // Проход по пунктам меню
    menu?.forEach(menuItem => {
        // Ссылка меню на материал
        const menuLink = menuItem?.attributes?.link
        // Исходный массив параметров
        const paramsArray = menuLink.split(/[\s?&]+/)?.filter(el => el !== '')        
        // Параметр id в виде массива с единственным элементом
        const idParamArray = paramsArray.filter(el => el.indexOf('id=') !== -1)        
        // Знаение параметра id в виде числа
        const idParamValue = Number(
                idParamArray.length
                // Если параметр id существует, то извлекаем его значение
                ? idParamArray[0].replace('id=', '')
                // Если параметр id не существует, то устанавливаем его значение, как 0
                : 0
        )
        
        // Если параметр id - положительное число
        if(idParamValue) {
            // URL пункта меню
            const menuURL = menuItem?.attributes?.note        
            // Добавление маппера
            menuToArticleMapper[menuURL] = idParamValue           
        }
    })    
 
    return menuToArticleMapper
}