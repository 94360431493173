// Node
import config from 'react-global-configuration'
// API
import axios from '../instance'

// Экспорт API статей
export const articlesAPI = {   
    getArticle(article_id = 1) {        
        return axios[config.get('api.article.type')]
            .get(config.get('api.article.url').replace('{article_id}', article_id))
            .then((response) => {                
                // Возврат ответа API
                return response?.data?.data
            })
            .catch(() => { return null })
    },    
}