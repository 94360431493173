import React from 'react'
// Node
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Alert, Box, Chip, Divider } from '@mui/material'
// Components
import {
    DateFormField,
    CheckBoxFormField,
    SelectFormField,
    TextFormField,
    TimeFormField,
} from './formFields'

/*
 * Компонент "Форма"
 */

// Экспорт компонента
export const BaseForm = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        validationSchema,
        initialValues,
        valuesHideRule,
        hiddenFields,
        setHiddenFields,
        fields,
        values,
        buttons,
        submitButtonDisableFields,
        toggleSubmitButtonDisable,
        onSubmit,
    } = props

    // Клон объекта валидации
    const validationSchemaClone = Object.assign({}, validationSchema)

    // Удаляем из клона объекта валидации правила для скрытых полей
    // и полей режима видимости кнопки отправки формы
    Array.from([])
        .concat(hiddenFields)
        .concat(submitButtonDisableFields)
        ?.forEach((field) => {
            if (Object.prototype.hasOwnProperty.call(validationSchemaClone, field)) {
                delete validationSchemaClone[field]
            }
        })

    // Схема валидации Yup на базе клона объекта валидации
    const yupValidationSchema = Yup.object().shape(validationSchemaClone)

    // Хук Formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: yupValidationSchema,
        onSubmit: (values) => {
            // Объект с результирующими данными
            const resultValues = Object.assign({}, values)

            // Если в получаемых значениях нет свойства из initialValues, берем его оттуда
            for (const key in formik.initialValues) {
                if (resultValues[key] === undefined) {
                    resultValues[key] = formik.initialValues[key]
                }
            }
            // Убираем из получаемых значений скрытые поля и поля режима видимости кнопки отправки формы
            Array.from([])
                .concat(hiddenFields)
                .concat(submitButtonDisableFields)
                ?.forEach((field) => {
                    if (values[field] !== undefined) {
                        delete resultValues[field]
                    }
                })
            // Проход по всем свойствам объекта с результирующими данными
            for (const prop in resultValues) {
                if (fields[prop]?.format) {
                    // Приведение целочисленных свойств к типу 'number'
                    if (
                        fields[prop].format === 'number' &&
                        typeof resultValues[prop] === 'string' &&
                        resultValues[prop]?.trim()?.length !== 0
                    ) {
                        resultValues[prop] = Number(resultValues[prop])
                    }
                    // Преобразование дат
                    if (fields[prop].format === 'date') {
                        const dateStrArray = resultValues[prop].split('.')
                        // Приведение к нужному формату даты
                        resultValues[
                            prop
                        ] = `${dateStrArray[2]}-${dateStrArray[1]}-${dateStrArray[0]}`
                    }
                }
                // Удаление пустых строковых значений
                if (
                    typeof resultValues[prop] === 'string' &&
                    resultValues[prop]?.trim()?.length === 0
                ) {
                    delete resultValues[prop]
                }
            }
            // Вызов колбэка отправки формы
            onSubmit && onSubmit(resultValues)
        },
    })

    // Установка массива имен полей, которые были изменены
    const [changedFields, setChangedFields] = React.useState([])

    // Метод проверки обязательного к заполнению поля
    const isFieldRequired = (field) => {
        return yupValidationSchema.fields[field]?.exclusiveTests?.required || false
    }

    // Контент полей формы
    const fieldsContent = []
    for (const key in fields) {
        // Свойства формы
        const formProps = {
            formik,
            values,
            valuesHideRule,
            fieldKey: key,
            changedFields,
            setChangedFields,
            hiddenFields,
            setHiddenFields,
        }
        // Свойства поля
        const fieldProps = {
            id: key,
            name: key,
            type: fields[key]?.type,
            label: fields[key]?.title,
            props: fields[key]?.props,
            fullWidth: true,
            required: isFieldRequired(key),
            disabled: fields[key]?.disabled ? fields[key]?.disabled : false,
            onChange: submitButtonDisableFields && submitButtonDisableFields.includes(key) ? toggleSubmitButtonDisable : null,
        }

        // Контент полей формы
        let fieldComponent = []
        switch (fields[key].type) {
            case 'text':
                fieldComponent = <TextFormField {...formProps} fieldProps={fieldProps} />
                break
            case 'select':
                fieldComponent = <SelectFormField {...formProps} fieldProps={fieldProps} />
                break
            case 'checkbox':
                fieldComponent = <CheckBoxFormField {...formProps} fieldProps={fieldProps} />
                break
            case 'date':
                fieldComponent = <DateFormField {...formProps} fieldProps={fieldProps} />
                break            
            case 'time':
                fieldComponent = <TimeFormField {...formProps} fieldProps={fieldProps} />
                break                
            case 'divider':
                fieldComponent = (
                    <Divider textAlign="center">
                        <Chip color={'secondary'} variant="outlined" label={fieldProps?.label} />
                    </Divider>
                )
                break
            default:
                break
        }

        fieldsContent.push(
            <Box sx={{ mb: 2 }}>
                {fieldComponent}
                {changedFields.includes(key) && formik.errors[key] && (
                    <Alert severity="warning">{formik.errors[key]}</Alert>
                )}
            </Box>,
        )
    }

    // Обработчик события отправки формы
    const handleFormSubmit = (event) => {
        // Пометка всех полей, как измененных
        const allFieldsChanged = []
        for (const key in fields) {
            allFieldsChanged.push(key)
        }
        setChangedFields(allFieldsChanged)
        // Отправка события в formik
        formik.handleSubmit(event)
    }

    return (
        <form onSubmit={handleFormSubmit}>
            {fieldsContent}
            {buttons}
        </form>
    )
}

// Свойства по умолчанию
BaseForm.defaultProps = {}

// Экспорт компонента
export default BaseForm
