import React from "react"
// Node
import { Link, Route, useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { Box, Grid } from '@mui/material'
// Blocks
import SliderBlock from './blocks/SliderBlock'
import MenuBlock from './blocks/MenuBlock'
// Layouts
import CalendarLayout from '../CalendarLayout'
// Pages
import MainPage from '../../pages/MainPage'
import CabinetPage from '../../pages/CabinetPage'
import CoachesPage from '../../pages/CoachesPage'
// Redux
import { setCurrentArticleId } from '../../../redux/reducers/articles-reducer'
// Own
import siteLogo from "./src/img/logo-spain.png"
import instagramSocial from "./src/img/instagram-white.png"
import whatsappSocial from "./src/img/whatsapp-white.png"
import useStyleMui from './styles/style.mui'

/*
 * Шаблон "Главный"
 */

// Компонент шаблона
const MainLayout = () => {    
    // Определение метода dispatch
    const dispatch = useDispatch()
    
    // История браузера
    const history = useHistory()    
    
    // Стиль шаблона
    const styleMui = useStyleMui()
    
    // Регулярное выражение УРЛ Кабинета
    const regExpCabinetPath = /^\/cabinet\/*[\w\d]*$/
    
    return (
        <>
            <Grid container spacing={6}>  
                <Grid item xs={9} sx={{mt: 1, mb: 1}}>
                        <Link to={'/'}>
                            <Box
                                onClick={ () => { 
                                        history.push('/')
                                        dispatch(setCurrentArticleId(1))
                                    }
                                }
                                sx={{ maxHeight: {xs: '70px', sm: '80px', md: '90px', lg: '100px'} }} 
                                component="img" 
                                src={siteLogo} 
                            />
                        </Link>                
                </Grid>
                <Grid item xs={3} sx={{mt: 1, mb: 1, display: 'flex'}}>
                    <MenuBlock />
                </Grid>
            </Grid>
            <Route render={({ location }) => 
                regExpCabinetPath.test(location.pathname) ? null : (
                    <Grid 
                        container spacing={6} 
                        sx={{ 
                            maxWidth: {xs: 'unset', lg: '900px'}, 
                            marginInline: {xs: 'none', lg: 'auto'}, 
                        }}
                    >  
                        <Grid item xs={12} sx={{mt: 1, mb: 1}}>                
                            <SliderBlock />
                        </Grid>
                    </Grid>
                )
            } />
                        
            <Grid 
                container spacing={6}
                sx={{ 
                    px: {xs: 0, lg: '48px', xl: '120px'},                    
                }}
                className={styleMui.content}
            >  
                <Grid item xs={12}>
                    <Route render={({ location }) => 
                        regExpCabinetPath.test(location.pathname) ? null : ( 
                            <MainPage />
                        )
                    } />
                    
                    <Route 
                        path={[
                            "/basketball_boys", "/basketball_girls",
                            "/gymnastics", "/coaches"
                        ]} 
                        exact render={() => <CoachesPage />} 
                    />  
                    
                    <Route path={["/cabinet", "/cabinet/:hash"]} exact render={() => <CabinetPage />} />  
                    <Route path="/" exact render={() => <CalendarLayout view={'main'} />} />
                    <Route path="/basketball_boys" exact render={() => <CalendarLayout view={'basketball_boys'} />} />
                    <Route path="/basketball_girls" exact render={() => <CalendarLayout view={'basketball_girls'} />} />
                    <Route path="/gymnastics" exact render={() => <CalendarLayout view={'gymnastics'} />} />
                </Grid>
            </Grid>
            <Grid 
                container
                sx={{ 
                    px: {xs: 0, lg: '48px', xl: '120px'},
                    px: 2, pt: 3
                }}
                className={styleMui.footer}
            >  
                <Grid item xs={6}>
                    Escuela de baloncesto &copy; 2023
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'right'}}>
                    <Box
                        onClick={ () => { 
                                window.open(process.env.REACT_APP_JOOMLA_SOCIAL_INSTAGRAM)
                            }
                        }
                        sx={{ px: {xs: 1, md: 2}, cursor: 'pointer' }} 
                        component="img" 
                        src={instagramSocial} 
                    />
                    <Box
                        onClick={ () => { 
                                window.open(process.env.REACT_APP_JOOMLA_SOCIAL_WHATSAPP)
                            }
                        }
                        sx={{ px: {xs: 1, md: 2}, cursor: 'pointer' }} 
                        component="img" 
                        src={whatsappSocial} 
                    />                    
                </Grid>
            </Grid>            
        </>
    )
}

// Экспорт шаблона
export default MainLayout