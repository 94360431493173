// Node
import { createSlice } from '@reduxjs/toolkit'
// API
import { calendarAPI } from '../../api/endpoints/calendarAPI'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState = {
    // Текущий пользователь
    currentUser: null,
    // Айди текущего пользователя
    currentUserId: 1,
}

// Слайс пользователей
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Установить текущего пользователя
        setCurrentUser(state, action) {                   
            state.currentUser = action.payload
        },
        // Установить айди текущего пользователя
        setCurrentUserId(state, action) {                   
            state.currentUserId = action.payload
        },        
    }
})

// Экспорт создателей действий
export const { setCurrentUser, setCurrentUserId } = userSlice.actions;
// Экспорт редьюсера
export default userSlice.reducer;

//THUNK CREATORS

// Получение статьи
export const getUser = (hash) => {    
    return (dispatch) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('user'))
                        
        // Получение данных через API календаря
        calendarAPI.getUser(hash).then((response) => {            
            // Установить полученную информацию
            dispatch(setCurrentUser(response))                      
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('user'))
        })
    }
}