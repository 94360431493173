import React from 'react'
// Node
import { useDispatch, useSelector  } from 'react-redux'
import { Home as HomeIcon, QueryBuilder as QueryBuilderIcon, SportsBasketball as SportsBasketballIcon, 
    SportsGymnastics as SportsGymnasticsIcon, Groups as GroupsIcon, Place as PlaceIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon} from '@mui/icons-material'
// Redux
import { setCurrentArticleId } from '../../../../../../redux/reducers/articles-reducer'

// Получить массив элементов меню
export const GetMenuItems = (props) => {
    // Преобразование пропсов в локальные константы        
    const { menu, history } = props

    // Определение метода dispatch
    const dispatch = useDispatch()
    
    // Маппер "меню - статья"
    const menuToArticleMapper = useSelector((state) => state.menuStore.menuToArticleMapper)
    
    // Стиль иконок
    const iconsStyle={color: '#d73f2e'}
    
    const iconsMapper = {
        HomeIcon: <HomeIcon sx={{...iconsStyle}} />,
        QueryBuilderIcon: <QueryBuilderIcon sx={{...iconsStyle}} />,
        SportsBasketballIcon: <SportsBasketballIcon sx={{...iconsStyle}} />,
        SportsGymnasticsIcon: <SportsGymnasticsIcon sx={{...iconsStyle}} />,
        GroupsIcon: <GroupsIcon sx={{...iconsStyle}} />,
        PlaceIcon: <PlaceIcon sx={{...iconsStyle}} />,
    }
    
    // Изображения слайдера
    const menuItems = []
    
    if(menu?.length) {
        menu?.forEach(menuItem => {
            const menuIconName = menuItem?.attributes?.params['menu-anchor_title']
            menuItems.push({
                name: menuItem?.attributes?.title,
                icon: iconsMapper[menuIconName] ? iconsMapper[menuIconName] : <KeyboardArrowRightIcon />,
                selected: `/${menuItem?.attributes?.note}` === history.location.pathname,
                onClick: () => { 
                    history.push(menuItem?.attributes?.note)
                    dispatch(setCurrentArticleId(
                        menuToArticleMapper[menuItem?.attributes?.note]
                        ? menuToArticleMapper[menuItem?.attributes?.note]
                        : 1
                    ))
                },
            })
        })
    }
    
    return menuItems    
}