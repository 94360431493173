export const getFields = (view, bookMode, sectionId) => {    
    const fields = {
        divider1: { title: 'Infomación sobre reservas', type: 'divider' },        
    }
    
    if(['main'].includes(bookMode) && ['admin'].includes(view)) {
        fields.section_id = { title: 'Sección', type: 'select', format: 'number' }
        if(sectionId) {
            fields.age_id = { title: 'Grupo de edad', type: 'select', format: 'number' }
        }
    }    
    if(['main'].includes(bookMode)) {
        fields.hall_id = { title: 'Número de gimnasio', type: 'text', format: 'number' }
        fields.book_date = { title: 'Fecha para registrarse', type: 'date', format: 'date' }
        fields.book_start_time = { title: 'Comenzando un entrenamiento', type: 'time', format: 'string' }
        fields.book_end_time = { title: 'Fin del entrenamiento', type: 'time', format: 'string' }               
    }
    if(['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode)) {
        fields.section_id = { title: 'Sección', type: 'select', format: 'number' }
        fields.age_id = { title: 'Grupo de edad', type: 'select', format: 'number' }
        fields.divider2 = { title: 'Información sobre el niño', type: 'divider' }        
        fields.child_full_name = { title: 'Nombre completo del niño', type: 'text', format: 'string' }
        fields.child_age = { title: 'Edad del niño', type: 'text', format: 'number' }            
    }
    
    if(!(['admin'].includes(view) && ['main'].includes(bookMode) && sectionId !== null)) {
        fields.divider3 = { title: 'Información personal de Booker', type: 'divider' }
        fields.full_name = { title: 'Nombre completo', type: 'text', format: 'string' }                
        fields.email = { title: 'Dirección de correo electrónico', type: 'text', format: 'string' }
        fields.phone = { title: 'Número de teléfono', type: 'text', format: 'string' }
    }
    if(!['admin'].includes(view)) {
        fields.personal = {
            title: 'Acepto el tratamiento de datos personales',
            type: 'checkbox',
            props: { label: '(de acuerdo con la ley de datos personales)' },
        }
    }

    return fields
}
