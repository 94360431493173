import React from 'react'
// Node
import { Link as RouterLink } from 'react-router-dom'
import { Card, Grid, Typography, CardContent } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
// Own
import { CardMediaStyle, CoverImgStyle, TitleStyle } from './components'
import defaultLogo from './src/img/default_logo.jpg'

/*
 * Компонент "Информационная карточка" (обертка над MaterialUI)
 */

export const MaterialCard = (props) => {
    // Преобразование пропсов в локальные константы
    const { logo, name, text } = props?.data  

    return (
        <Grid item xs={12} sm={6} md={4} xl={3}>
            <Card 
                sx={{ 
                    position: 'relative', 
                    borderRadius: '16px',
                    "&:hover": {                        
                        cursor: 'pointer',
                    },
                }}                
            >                
                <CardMediaStyle >                                       
                    <CoverImgStyle alt={name} src={logo || defaultLogo} />                    
                </CardMediaStyle>                            
                <CardContent sx={{pt: 2}}>           
                    <TitleStyle
                        to="#"
                        color="inherit"
                        variant="h6"
                        underline="hover"
                        component={RouterLink}
                        sx={{
                            "&:hover": {
                                color: 'inherit',
                                textDecoration: 'none',                                
                            },                 
                        }}                        
                    >
                        {name}
                    </TitleStyle>
                    <div 
                        dangerouslySetInnerHTML={
                            { __html: text || '' }
                        } 
                    />                            
                </CardContent>
            </Card>
        </Grid>        
    )
}

// Свойства по умолчанию
MaterialCard.defaultProps = { }

// Экспорт компонента
export default MaterialCard