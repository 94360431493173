import React from "react"
// Node
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
// Components
import MaterialCard from '../../components/material/MaterialCard'
// Redux
import { getCoaches } from '../../../redux/reducers/coaches-reducer'

/*
 * Страница "Тренеры"
 */

// Компонент страницы
const CoachesPage = (props) => {
    // Преобразование пропсов в локальные константы
    const { coaches, getCoaches, sectionId } = props
    
    // История браузера
    const history = useHistory()
    // Текущий УРЛ
    let currentURL = history.location.pathname   
    
    // Хук эффекта
    React.useEffect(() => {                 
        // Получить список тренеров
        getCoaches()
    }, [getCoaches])
    
    // Условие показа тренеров в зависимости от секции
    const showCoachesCondition = (item) => 
        ( 
            Number(sectionId) === Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION)
            && 
            Number(item?.attributes?.catid) === Number(process.env.REACT_APP_JOOMLA_COACH_CATEGORY_BASKETBALL)
        ) ||
        ( 
            Number(sectionId) === Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)
            && 
            Number(item?.attributes?.catid) === Number(process.env.REACT_APP_JOOMLA_COACH_CATEGORY_GYMNASTICS)
        ) ||
        ['/coaches'].includes(currentURL)                   
    
    return (               
        <Box sx={{px: 2, my: 2}}>
            <Typography variant="h6" gutterBottom component="div">
                Entrenadores
            </Typography>
            <Grid container spacing={2}>
                {coaches
                    ?.filter(item => showCoachesCondition(item))
                    ?.map((coach, index) => {
                        const params = JSON.parse(coach?.attributes?.params)                    
                        const data = {
                            logo: params?.imageurl,
                            name: coach?.attributes?.name,
                            text: coach?.attributes?.description,
                        }
                        //console.log(data)
                        return (
                            <MaterialCard data={data} key={`companiesInfoCard_${index}`} />                        
                        )
                    })
                }
            </Grid>
        </Box>
    )    
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state) => (    
    {        
        coaches: state.coachesStore?.coaches,
        sectionId: state.calendarStore?.sectionId,
        currentArticleId: state.articlesStore?.currentArticleId,
    }
)

// Экспорт страницы
export default connect(mapStateToProps, { getCoaches })(CoachesPage)