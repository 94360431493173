import React from 'react'
// Node
import moment from 'moment'
// Components
import MaterialDatePicker from '../../../../material/MaterialDatePicker'

/*
 * Компонент "Поле формы "Дата""
 */

// Экспорт компонента
export const DateFormField = (props) => {
    // Преобразование пропсов в локальные константы
    const { formik, fieldKey, fieldProps, changedFields, setChangedFields } = props

    const [selectDate, setSelectDate] = React.useState(
        Object.prototype.hasOwnProperty.call(formik.initialValues, fieldKey)
            ? formik.initialValues[fieldKey]
            : null,
    )
    const handleDateChange = (value: string) => {
        const regExpDate = /^\d{1,2}.\d{1,2}.\d{4}$/       
        const dateStr = moment(value).format("DD.MM.YYYY")        
        const formatedValue = regExpDate.test(dateStr) ? dateStr : null
        
        if (!changedFields.includes(fieldKey)) {
            setChangedFields([...changedFields, fieldKey])
        }
        setSelectDate(value)
        formik.setFieldValue(fieldKey, formatedValue)
    }

    return <MaterialDatePicker {...fieldProps} date={selectDate} onChange={handleDateChange} />
}

// Свойства по умолчанию
DateFormField.defaultProps = {}

// Экспорт компонента
export default DateFormField
