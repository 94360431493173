import React from 'react'
// Node
import { Box, Divider, MenuItem } from '@mui/material'
// Own
import { StyledMenu } from './styles/style.mui'

/*
 * Компонент "Меню" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialMenu = (props) => {
    // Преобразование пропсов в локальные константы
    const { id, sx, anchorOrigin, menuItems } = props
    // Установка якоря на элемент, вызывающий меню
    const [anchorEl, setAnchorEl] = React.useState(null)
    // Метод открытия меню
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    // Метод закрытия меню
    const handleMenuClose = () => {
        setAnchorEl(null)
    }
    
    return (
        <Box sx={sx}>
            <div onClick={handleMenuOpen}>
                {
                    // Элемент при клике на который открывается меню
                    // Этот элемент должен быть обернут в MaterialMenu
                    props.children
                }
            </div>
            <StyledMenu
                id={id}                           
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >          
                {
                    // Маппинг массива элементов меню
                    menuItems.map((item) =>
                        item.type === 'divider'
                        ? <Divider sx={{ my: 0.5 }} />
                        : <MenuItem 
                            key={item.name} 
                            onClick={
                                () => item.onClick && item.onClick()
                            }
                            selected={item.selected}
                        >
                            {item.icon}
                            {item.name}
                        </MenuItem>                        
                    )
                }                
            </StyledMenu>
        </Box>        
    )
}

// Свойства по умолчанию
MaterialMenu.defaultProps = {}

// Экспорт компонента
export default MaterialMenu
