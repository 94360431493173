// Node
import * as Yup from 'yup'
import moment from 'moment'

// Текущая дата
const currentDate = new Date()

export const getSignupSchema = (view, bookMode, sectionId) => {
    const SignupSchema = { }
    if(['main'].includes(bookMode) && ['admin'].includes(view)) {
        SignupSchema.section_id = Yup.number()        
            .required('Requerido para llenar')
        if(sectionId) {
            SignupSchema.section_id = Yup.number()        
                .required('Requerido para llenar')            
        }
    }
    if(['main'].includes(bookMode)) {
        SignupSchema.hall_id = Yup.number()        
            .required('Requerido para llenar')
        SignupSchema.book_date = Yup.string()
            .matches(/^\d{1,2}.\d{1,2}.\d{4}$/, 'El formato de la fecha debe ser: "dd.mm.yyyy"')
            .test(
                'test-date',
                `La fecha de reserva no debe ser inferior a ${moment(currentDate).format("DD/MM/YYYY")}`,
                (bookDateStr) => {
                    const bookDateTimestamp = moment(moment(bookDateStr).format("DD/MM/YYYY")).format("X")
                    const currentDateTimestamp = moment(moment(currentDate).format("MM/DD/YYYY")).format("X")                                                            
                    if(bookDateTimestamp < currentDateTimestamp) {
                        return false
                    }
                    return true
                },
            )
            .required('Requerido para llenar el formato "dd.mm.yyyy"')
            .nullable()
        SignupSchema.book_start_time = Yup.string()
            .matches(/^\d{1,2}:?(00|30)$/, 'El formato de hora debe ser: "hh.mm" y debe ser múltiplo de :00 o :30')            
            .required('Requerido para llenar el formato "hh.mm" y debe ser múltiplo de :00 o :30')
            .nullable()
        SignupSchema.book_end_time = Yup.string()
            .matches(/^\d{1,2}:?(00|30)$/, 'El formato de hora debe ser: "hh.mm" y debe ser múltiplo de :00 o :30')
            .required('Requerido para llenar el formato "hh.mm" y debe ser múltiplo de :00 o :30')
            .nullable()
    }
    if(['basketball_boys', 'basketball_girls', 'gymnastics'].includes(bookMode)) {
        SignupSchema.section_id = Yup.number()        
            .required('Requerido para llenar')
        SignupSchema.age_id = Yup.number()        
            .required('Requerido para llenar')        
        SignupSchema.child_full_name = Yup.string()
            .min(1, 'El número de caracteres debe ser al menos 1')
            .max(99, 'El número de caracteres no debe ser superior a 99')
            .required('Requerido para llenar')
        SignupSchema.child_age = Yup.number()        
            .required('Requerido para llenar')            
    }
    
    if(!(['admin'].includes(view) && ['main'].includes(bookMode) && sectionId !== null)) {
        SignupSchema.full_name = Yup.string()
            .min(1, 'El número de caracteres debe ser al menos 1')
            .max(99, 'El número de caracteres no debe ser superior a 99')
            .required('Requerido para llenar')
        SignupSchema.email = Yup.string()
            .email(
                'El formato del correo electrónico debe ser: "nombre@subdominio.dominio". La información de la reserva se enviará a esta dirección',
            )
            .required(
                'Requerido para llenar el formato "nombre@subdominio.dominio". La información de la reserva se enviará a esta dirección',
            )
        SignupSchema.phone = Yup.string()
            .matches(
                /^(\+?\d{0,4})?-?(\(?\d{3}\)?)-?(\d{2,3})-?(\d{2,3})?-?(\d{2})?$/,
                'El formato del número de teléfono no debe contener espacios. Se aceptan dígitos del 0 al 9, símbolos "-", "+" y corchetes',
            )
    }
    
    if(!['admin'].includes(view)) {
        SignupSchema.personal = Yup.string().required('Requerido para llenar')
    }
    
    return SignupSchema
}
