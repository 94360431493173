import React from "react"
// Node
import moment from 'moment'
import { connect } from 'react-redux'
import { Backdrop, Box, Button, Chip, CircularProgress, Grid, Typography, useTheme } from '@mui/material'
import { SportsBasketball as SportsBasketballIcon, SportsGymnastics as SportsGymnasticsIcon, 
    Groups as GroupsIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
    Approval as ApprovalIcon, Settings as SettingsIcon,
    CalendarMonth as CalendarMonthIcon, GroupAdd as GroupAddIcon} from '@mui/icons-material'
// Components
import Calendar from '../../components/common/Calendar'
import BookComponent from './components/BookComponent'
import ManageComponent from './components/ManageComponent'
import AgeComponent from './components/AgeComponent'
import MaterialMenu from '../../components/material/MaterialMenu'
import MaterialTabs from '../../components/material/MaterialTabs'
// Redux
import { setBookMode, setHallId, setSectionId, getEvents, bookEvent, bookChild, getAges } from '../../../redux/reducers/calendar-reducer'
import { setSnackOpen, setSnackMessage, setSnackStatus } from '../../../redux/reducers/messages-reducer'
// Own
import { getCalendarEvents } from './data/data.calendar_events'

/*
 * Шаблон "Календарь"
 */

// Компонент шаблона
const CalendarLayout = (props) => {
    // Преобразование пропсов в локальные константы
    const { hallId, setHallId, sectionId, setSectionId, eventList, getEvents, loadingCalendarComplete, 
        bookEvent, bookChild, ageList, getAges,
        snackOpen, snackMessage, snackStatus,
        setSnackOpen, setSnackMessage, setSnackStatus, bookMode, setBookMode, view, } = props    

    // Тема MUI
    const theme = useTheme()    
    
    // Установка объекта с информацией о бронировании
    const [bookFields, setBookFields] = React.useState({})

    // Хук эффекта
    React.useEffect(() => {        
        if(['main'].includes(bookMode)) {
            setBookFields({
                hall_id: hallId, book_date: '', book_start_time: '', book_end_time: ''
            })
        }        
    }, [bookMode])
    
    // Хук эффекта
    React.useEffect(() => {          
        // Установить режим бронирования
        setBookMode(view)
    }, [setBookMode, view])
    
    // Хук эффекта
    React.useEffect(() => {        
        // Получить события календаря
        getEvents()
        // Получить cписок возрастных групп
        getAges()
    }, [getEvents, hallId])
    
    // Получение массива событий календаря 
    const events = getCalendarEvents({ eventList })
    
    // Элементы меню выпадающего списка "Номер зала"
    const hallMenuItems = [1, 2].map(item => (
        {
            name: `Gimnasio #${item}`,
            icon: <ApprovalIcon />,        
            selected: Number(hallId) === Number(item),
            onClick: () => setHallId(item),        
        }
    ))
    
    // Элементы меню выпадающего списка "Режим бронирования"
    const bookModeMenuItems = ['main', 'basketball_boys', 'basketball_girls', 'gymnastics'].map(item => {
        let itemName = ''
        // Установка информации в зависимости от режима бронирования
        switch(item) {
            // Аренда
            case 'main' : itemName = 'Reserva'; break;
            // Баскетбол M
            case 'basketball_boys' : itemName = 'Baloncesto M'; break;
            // Баскетбол F
            case 'basketball_girls' : itemName = 'Baloncesto F'; break;
            // Гимнастика
            case 'gymnastics' : itemName = 'Gimnasia'; break;            
            default: ;
        }        
        return {
            name: `${itemName}`,
            icon: <SettingsIcon />,        
            selected: bookMode === item,
            onClick: () => setBookMode(item),        
        }
    })
    
    // Элементы меню выпадающего списка "Секция"
    const sectionMenuItems = [
        null,
        Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION),
        Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION)
    ].map(item => {
        let icon = <GroupsIcon />
        let title = ''
        // Установка информации в зависимости от типа секции
        switch(item) {
            // Аренда
            case null :                 
                title = 'Alquilar'
                icon = <GroupsIcon />
                break        
            // Баскетбол
            case Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION) :                 
                title = 'Baloncesto'
                icon = <SportsBasketballIcon />
                break
            // Гимнастика
            case Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION) :                 
                title = 'Gimnasia'
                icon = <SportsGymnasticsIcon />
                break                                            
            default: ;
        }
        
        return {
            name: title,
            icon: icon,        
            selected: Number(sectionId) === Number(item),
            onClick: () => setSectionId(item),        
        }
    })
    
    // Установка маркера открытия диалога с управлением бронированием
    const [isManageOpen, setIsManageOpen] = React.useState(false)
    
    // Установка информации по управлению бронированием
    const [eventInfo, setEventInfo] = React.useState({})
    
    // Клик по событию календаря
    const eventClick = (info) => {
        //console.log('eventClick', info.startStr)
        if(['admin'].includes(view)) {
            setEventInfo(info)        
            setIsManageOpen(true)
        }
    }
    
    // Клик по пустой ячейке календаря
    const cellClick = (info) => {
        if(['main'].includes(bookMode)) {
            setBookFields({
                hall_id: hallId,         
                book_date: moment(info.startStr).toDate(), 
                book_start_time: moment(info.startStr).toDate(), 
                book_end_time: moment(info.endStr).toDate(), 
            })
        }
    }
    // Стиль иконок
    const chipStyle={color: '#ffffff', px: 1, mr: 1, mb: 1,}
    // Стиль кнопки выпадающего меню
    const buttonStyle = { color: '#212529', backgroundColor: '#ffffff', borderColor: '#ffffff' }
    
    // Контент календаря
    const calendarContent = 
        <Box sx={{px: 2}}>
            <Typography variant="h6" gutterBottom component="div">
                Horario de gimnasio
            </Typography>
            {loadingCalendarComplete
                ? <Grid container>  
                    <Grid item xs={12}>
                        {['admin', 'main', 'basketball_boys', 'basketball_girls'].includes(view)
                            ? <Chip
                                color={'primary'}
                                sx={{backgroundColor: theme.palette.orange.main, ...chipStyle}} 
                                label={'baloncesto'}
                                icon={<SportsBasketballIcon />}
                            /> : []
                        }
                        {['admin', 'main', 'gymnastics'].includes(view)
                            ? <Chip
                                color={'primary'}
                                sx={{backgroundColor: theme.palette.violet.main, ...chipStyle}}
                                label={'gimnasia'}
                                icon={<SportsGymnasticsIcon />}
                            /> : []
                        }
                        {['admin', 'main'].includes(view)
                            ? <Chip
                                color={'primary'}
                                sx={{backgroundColor: theme.palette.green.main, ...chipStyle}}
                                label={'alquilar ocupado'}
                                icon={<GroupsIcon />}
                            /> : []
                        }
                        {['admin', 'main'].includes(view)
                            ? <Chip 
                                color={'primary'}
                                sx={{backgroundColor: theme.palette.grey.main, ...chipStyle}}
                                label={'alquilar reservado'}
                                icon={<GroupsIcon />}
                            /> : []
                        }
                    </Grid>
                    <Grid item xs={12} sx={{mt: 1}}>
                        <MaterialMenu
                            id={'hall-menu'}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            menuItems={hallMenuItems}
                            sx={{ float: 'left', pr: 2}}
                        >                                                                                
                            <Button                           
                                aria-controls={'hall-menu'}
                                aria-haspopup={true}
                                variant={'outlined'}
                                disableElevation                            
                                endIcon={<KeyboardArrowDownIcon />}
                                sx={{...buttonStyle}}
                            >
                                Número de gimnasio #{hallId}
                            </Button>                        
                        </MaterialMenu>
                    </Grid>
                    {view === 'admin'
                        ? <>
                            <Grid item xs={12} sx={{mt: 1}}>
                                <MaterialMenu
                                    id={'book-mode-menu'}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    menuItems={bookModeMenuItems}
                                    sx={{ float: 'left', pr: 2}}
                                >                                                                                
                                    <Button                           
                                        aria-controls={'book-mode-menu'}
                                        aria-haspopup={true}
                                        variant={'outlined'}
                                        disableElevation                            
                                        endIcon={<KeyboardArrowDownIcon />}
                                        sx={{...buttonStyle}}
                                    >
                                        Modo #{bookModeMenuItems.find(item => item.selected)?.name}
                                    </Button>                        
                                </MaterialMenu>                            
                                {bookMode === 'main'
                                    ? <MaterialMenu
                                        id={'section-menu'}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        menuItems={sectionMenuItems}
                                        sx={{ float: 'left', pr: 2}}
                                    >                                                                                
                                        <Button                           
                                            aria-controls={'section-menu'}
                                            aria-haspopup={true}
                                            variant={'outlined'}
                                            disableElevation                            
                                            endIcon={<KeyboardArrowDownIcon />}
                                            sx={{...buttonStyle}}
                                        >
                                            Sección #{sectionMenuItems.find(item => item.selected)?.name}
                                        </Button>                        
                                    </MaterialMenu>                                
                                    : []
                                }
                            </Grid>
                        </>
                        : []
                    }

                    <Grid item xs={12}>
                        <BookComponent
                            sectionId={sectionId}
                            bookFields={bookFields} 
                            ageList={ageList}
                            bookEvent={bookEvent} 
                            bookChild={bookChild}
                            view={view}
                            bookMode={bookMode} 
                            snackOpen={snackOpen}
                            snackMessage={snackMessage}
                            snackStatus={snackStatus}
                            setSnackOpen={setSnackOpen}
                            setSnackMessage={setSnackMessage}
                            setSnackStatus={setSnackStatus}
                        />
                        <Calendar
                            view={view}
                            events={events}
                            eventClick={eventClick}
                            cellClick={cellClick}
                        />
                        {['admin'].includes(view)
                            ? <ManageComponent 
                                isManageOpen={isManageOpen}
                                setIsManageOpen={setIsManageOpen}
                                eventInfo={eventInfo}
                              /> 
                            : []
                        }
                    </Grid>
                </Grid>
                : <Backdrop
                    sx={{ color: '#fff', zIndex: 2 }}
                    open={!loadingCalendarComplete}                
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </Box>

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);
    
    return (
        <>
            {['admin'].includes(view)            
                ? <MaterialTabs 
                    tabs={[
                        {label: 'Calendario', icon: <CalendarMonthIcon />, content: calendarContent, onClick: () => setActiveTabIndex(0) },
                        {label: 'Grupos de edad', icon: <GroupAddIcon />, content: <AgeComponent ageList={ageList} />, onClick: () => setActiveTabIndex(1) },                  
                    ]}
                    activeTabIndex={activeTabIndex}                    
                />
                : calendarContent
            }
        </>
    )    
}
    
// Маппинг стейта в пропсы шаблона
const mapStateToProps = (state) => (    
    {        
        hallId: state.calendarStore?.hallId,
        sectionId: state.calendarStore?.sectionId,        
        bookMode: state.calendarStore?.bookMode,
        eventList: state.calendarStore?.eventList,
        ageList: state.calendarStore?.ageList,
        
        snackOpen: state.messagesStore?.snackOpen,
        snackMessage: state.messagesStore?.snackMessage,
        snackStatus: state.messagesStore?.snackStatus,
        
        loadingCalendarComplete: state.loadStore.loadingComplete?.calendar,
    }
)

// Экспорт шаблона
export default connect(mapStateToProps, 
        {
            setHallId, setSectionId, getEvents, bookEvent, bookChild, getAges,
            setSnackOpen, setSnackMessage, setSnackStatus, setBookMode
        }
    )(CalendarLayout)    