import React from 'react'
// Node
import { connect } from 'react-redux'
// Components
import MaterialSlider from '../../../../components/material/MaterialSlider'
// Redux
import { getSlider } from '../../../../../redux/reducers/sliders-reducer'
// Own
import { getSliderImages } from './data/data.slider_images'

/*
 * Блок "Слайдер на главной странице"
 */

// Экспорт блока
export const SliderBlock = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentSlider, loadingSliderComplete, getSlider} = props
    
    // Хук эффекта
    React.useEffect(() => {        
        // Получить слайдер
        getSlider()
    }, [getSlider])
    
    // Получение массива элементов меню 
    const sliderImages = getSliderImages({slides: currentSlider})
    
    return (
        <>
            {loadingSliderComplete
                ? <MaterialSlider images={sliderImages} />
                : []
            }
        </>
    )
}

// Свойства по умолчанию
SliderBlock.defaultProps = {}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state) => (    
    {        
        currentSlider: state.slidersStore?.currentSlider,        
        loadingSliderComplete: state.loadStore.loadingComplete?.slider,
    }
)

// Экспорт блока
export default connect(mapStateToProps, {getSlider})(SliderBlock)    