import React from 'react'
// Node
import { Tabs, Tab, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

/*
 * Компонент "Вкладки" (обертка над MaterialUI)
 */

// Компонент панели таба
const TabPanel = (props) => {
    // Преобразование пропсов в локальные константы
    const { tabBoxStyle, children, value, index, showedTabIndexes, renderOnFirstChangeOnly, ...other } = props
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {(value === index || (renderOnFirstChangeOnly && showedTabIndexes.includes(index))) && (
                <Box sx={tabBoxStyle}>
                  {children}
                </Box>
            )}
        </div>
    )
}

// Индексы просмотренных табов
let showedTabIndexes = []
    
// Экспорт компонента
export const MaterialTabs = (props) => {
    // Преобразование пропсов в локальные константы
    const { 
        tabs, activeTabIndex, variant, onClick, onClose, 
        tabStyle, tabBoxStyle, orientation, renderOnFirstChangeOnly,
        visibility,
    } = props    
    
    // Установка локального значения переменной value
    const [value, setValue] = React.useState(activeTabIndex || 0)    
    
    // Событие переключения табов
    const handleChange = (event, newValue) => {
        setValue(newValue)        
        onClick && onClick(newValue)
    }
    
    // Хук эффекта
    React.useEffect(() => {        
        setValue(activeTabIndex)
        if(!showedTabIndexes.includes(value)) {
            showedTabIndexes.push(value)
        }
    }, [activeTabIndex, value])
    
    // Контент лейблов и панелей табов
    const TabLabelsContent = []
    const TabPanelsContent = []
    tabs?.forEach((oneTab, index: number) => {        
        TabLabelsContent.push(
            <Tab
                sx={tabStyle}
                label={<Box sx={{pt: 0}}>{oneTab.label}</Box>}
                key={`tabLabel_${index}`}
                icon={
                    <Box>
                        <Box sx={{float: 'left'}}>{oneTab.icon}</Box>
                        {onClose && 
                            <Box sx={{float: 'right'}}>
                                <CloseIcon
                                    fontSize="10px"
                                    color="secondary"
                                    onClick={
                                        (e) => {
                                            onClose(oneTab?.object)
                                            e.stopPropagation()
                                        }
                                    }
                                />
                            </Box>
                        }
                    </Box>
                }
                onClick={oneTab.onClick}
            />
        )
        TabPanelsContent.push(
            <TabPanel 
                value={value}
                index={index}
                tabBoxStyle={tabBoxStyle}
                key={`tabPanel_${index}`}
                showedTabIndexes={showedTabIndexes}
                renderOnFirstChangeOnly={renderOnFirstChangeOnly}
            >
                { oneTab.content }
            </TabPanel>
        )
    })    
    
    return (  
        <Box>
            {(visibility === 'tabs' || visibility === '') &&
                <Tabs        
                    variant={variant}
                    scrollButtons={variant === 'scrollable' ? true : false}
                    orientation={orientation ? orientation : 'horizontal'}                                
                    allowScrollButtonsMobile={true}
                    value={value}
                    onChange={handleChange}                
                    textColor="inherit"               
                >
                    { TabLabelsContent }                
                </Tabs>            
            }          
            {(visibility === 'panels' || visibility === '') &&
                <>
                    { TabPanelsContent }
                </>
            }
        </Box>
    )
}

// Свойства по умолчанию
MaterialTabs.defaultProps = { 
    activeTabIndex: 0,
    variant: 'fullWidth',
    tabStyle: {},
    tabBoxStyle: { p: 3 },
    renderOnFirstChangeOnly: true,
    visibility: '',
}

// Экспорт компонента
export default MaterialTabs