import React from 'react'
// Node
import _ from 'lodash-contrib'
import moment from 'moment'
import { connect } from 'react-redux'
import { Alert, Backdrop, Box, Button, CircularProgress, Typography } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
// Components
import MaterialDialog from '../../../../components/material/MaterialDialog'
import MaterialTable from '../../../../components/material/MaterialTable'
import MaterialMenu from '../../../../components/material/MaterialMenu'
// Redux
import { getEvent, updateEvent, deleteEvent, updateChild, deleteChild } from '../../../../../redux/reducers/calendar-reducer'
// Own
import {getTableData} from './data/data.table_rows'

/*
 * Компонент "Управление бранированием"
 */

// Экспорт компонента
export const ManageComponent = (props) => {
    // Преобразование пропсов в локальные константы
    const { isManageOpen, setIsManageOpen, eventInfo,
        currentEvent, getEvent, updateEvent, deleteEvent, 
        currentChild, updateChild, deleteChild, loadingEventComplete } = props
    
    // Расширенные свойства события
    const extendedProps = eventInfo?._def?.extendedProps   
    
    // Хук эффекта
    React.useEffect(() => {
        // Получать событие только при открытии диалога
        if(isManageOpen) {    
            getEvent(extendedProps?.book_id)        
        }
    }, [isManageOpen])    
    
    // Элементы меню выпадающего списка "Статус бронирования"
    const statusMenuItems = (currentStatusId, updatedItemId, updateType) => [2, 3].map(item => (
        {
            name: Number(item) === Number(process.env.REACT_APP_JOOMLA_BOOK_STATUS) ? 'reservado' : 'ocupado',
            icon: null,        
            selected: Number(currentStatusId) === Number(item),
            onClick: () => {                
                if(updateType === 'event') {
                    updateEvent(updatedItemId, {status_id: item})
                }
                if(updateType === 'child') {
                    updateChild(updatedItemId, {status_id: item})
                }                
            }
        }
    ))    
    
    // Установка локального значения открытия диалога удаления
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    // Установка объекта с информацией об удалении объекта
    const [deleteInfo, setDeleteInfo] = React.useState({})  
    
    const dialogTitle = 'Información sobre el inquilino'
    let dialogContent = []
    let dialogFullWidth = false
    
    switch(extendedProps?.section_id) {
        // Баскетбол
        case Number(process.env.REACT_APP_JOOMLA_BASKETBALL_SECTION) :            
        // Гимнастика
        case Number(process.env.REACT_APP_JOOMLA_GYMNASTICS_SECTION) :
            
            // Получение данных таблицы
            const tableData = getTableData({ 
                currentEvent, extendedProps, setDeleteInfo, 
                setShowDeleteModal, statusMenuItems, currentChild
            })
            
            dialogContent = loadingEventComplete
            ? <> 
                <Alert sx={{mt: 2}} variant="outlined" icon={false} severity="error">
                    <Box sx={{mt: 1}}>
                        <Typography color={'primary'}>Infomación sobre reservas:</Typography>
                        <Typography>Sección: {eventInfo?._def?.title}</Typography>
                        <Typography>Estado: {extendedProps?.status}</Typography>                    
                        <Typography>Hora de inicio: 
                            {` ${moment(extendedProps?.start_time).format('DD.MM.YYYY')} - ${moment(extendedProps?.start_time).format('HH:mm')}`}
                        </Typography>
                        <Typography>Нora de finalización: 
                            {` ${moment(extendedProps?.end_time).format('DD.MM.YYYY')} - ${moment(extendedProps?.end_time).format('HH:mm')}`}
                        </Typography>                            
                    </Box>
                    <Box sx={{mt: 1}}>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setDeleteInfo({type: 'event', id: extendedProps?.book_id})
                                setShowDeleteModal(true)
                            }}
                        >
                            Cancelar reserva
                        </Button>
                    </Box>
                </Alert>
                <Box sx={{mt: 3, mb: 1}}>
                    <Typography  color={'secondary'}>Lista de visitantes:</Typography>                                            
                </Box>
                {!_.isEmpty(tableData.rows)
                ? <>                    
                    <MaterialTable 
                        rows={tableData.rows} 
                        headers={tableData.headers}             
                    />
                </>
                : <Alert sx={{mt: 2}} variant="outlined" icon={false} severity="error">
                    No hay visitantes
                </Alert>
                }
            </>
            : <Backdrop
                sx={{ color: '#fff', zIndex: 2 }}
                open={!loadingEventComplete}                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            dialogFullWidth = !_.isEmpty(tableData.rows) ? true : false
            break
        // Аренда
        case Number(process.env.REACT_APP_JOOMLA_RENT_SECTION) :                
            dialogContent =
                <Alert sx={{mt: 2}} variant="outlined" icon={false} severity="error">
                    <Box sx={{mt: 1}}>
                        <Typography color={'primary'}>Infomación sobre reservas:</Typography>
                        <Typography>Sección: {eventInfo?._def?.title}</Typography>
                        <Typography sx={{float: 'left', width: '100%'}}>
                            <Box sx={{float: 'left'}}>Estado: </Box>                            
                            <MaterialMenu
                                id={'status-book-menu'}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                menuItems={
                                    statusMenuItems(
                                        currentEvent?.attributes?.status_id, 
                                        extendedProps?.book_id,
                                        'event'
                                    )
                                }
                                sx={{ float: 'left', pl: '5px'}}
                            >                                                                                
                                <Button                           
                                    aria-controls={'status-book-menu'}
                                    aria-haspopup={true}
                                    variant={'outlined'}
                                    disableElevation                            
                                    endIcon={<KeyboardArrowDownIcon />}                                    
                                >
                                    {
                                        Number(currentEvent?.attributes?.status_id) === 2 
                                        ? 'reservado' : 'ocupado'
                                    }
                                </Button>                        
                            </MaterialMenu>                                                                                
                        </Typography>                    
                        <Typography>Hora de inicio: 
                            {` ${moment(extendedProps?.start_time).format('DD.MM.YYYY')} - ${moment(extendedProps?.start_time).format('HH:mm')}`}
                        </Typography>
                        <Typography>Нora de finalización: 
                            {` ${moment(extendedProps?.end_time).format('DD.MM.YYYY')} - ${moment(extendedProps?.end_time).format('HH:mm')}`}
                        </Typography>
                        <Typography color={'primary'}>Informacion personal:</Typography>
                        <Typography>Nombre completo: {extendedProps?.user_full_name}</Typography>
                        <Typography>Dirección de correo electrónico: {extendedProps?.user_email}</Typography>
                        <Typography>Número de teléfono: {extendedProps?.user_phone}</Typography>
                    </Box>
                    <Box sx={{mt: 1}}>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setDeleteInfo({type: 'event', id: extendedProps?.book_id})
                                setShowDeleteModal(true)
                            }}
                        >
                            Cancelar reserva
                        </Button>
                    </Box>
                </Alert>        
            break                                             
        default: ;
    }
    
    return(     
        <>
            <MaterialDialog title={dialogTitle}
                content={dialogContent}
                showModal={isManageOpen}
                fullWidth={dialogFullWidth}
                showApplyButton={false}
                onCloseText={'Cerca'}                
                onClose={() => { setIsManageOpen(false) }} 
            />
            <MaterialDialog title="Cancelar la reserva"
                content="De verdad quieres cancelar?"
                showModal={showDeleteModal}
                onApplyText={'Sí'}
                onCloseText={'No'}
                onApply={() => { 
                    setShowDeleteModal(false)                    
                    if(deleteInfo?.type === 'event') {
                        setIsManageOpen(false)                    
                        deleteEvent(deleteInfo?.id)                        
                    }
                    if(deleteInfo?.type === 'child') {
                        deleteChild(deleteInfo?.id)
                        getEvent(extendedProps?.book_id)
                    }                    
                }}
                onClose={() => { setShowDeleteModal(false) }} 
            />            
        </>
    )
}

// Маппинг стейта в пропсы компонента
const mapStateToProps = (state) => (    
    {        
        currentEvent: state.calendarStore?.currentEvent,
        currentChild: state.calendarStore?.currentChild,
        loadingEventComplete: state.loadStore.loadingComplete?.event,
    }
)

// Экспорт компонента
export default connect(mapStateToProps, 
    { getEvent, updateEvent, deleteEvent, updateChild, deleteChild }
)(ManageComponent)