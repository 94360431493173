// Node
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme= useTheme();
    
    return makeStyles(() => ({
        dot: {
            cursor: 'pointer',
            height: '10px !important',
            margin: '8px 6px !important',
            width: '10px !important',
        },
        dotActive: {
            backgroundColor: `${theme.palette.orange.main} !important`
        }
    }))();   
}

// Экспорт конструктора стилей
export default useStyleMui;
