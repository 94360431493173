import React from 'react'
// Node
import { useTheme } from '@mui/material/styles';
import { Box, Button, MobileStepper, Paper, Typography} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
// Own
import useStyleMui from "./styles/style.mui"

/*
 * Компонент "Слайдер" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialSlider = (props) => {
    // Преобразование пропсов в локальные константы
    const { images, showTitle, showNavButtons } = props
    
    // Стили
    const styleMui = useStyleMui()    
    
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
    
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);    

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };
    
    return(     
        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            {showTitle
                ? <Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                    }}
                >
                    <Typography>{images[activeStep] ? images[activeStep].label : ''}</Typography>
                </Paper>
                : []
            }
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: 'auto',
                                    display: 'block',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    width: '100%',
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                sx={showNavButtons ? {} : {margin: 'auto', width: 'fit-content'}}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                classes={{    
                    dot: styleMui.dot,
                    dotActive: styleMui.dotActive,
                }}                
                nextButton={
                    showNavButtons
                    ? <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                    : []
                }
                backButton={
                    showNavButtons
                    ? <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                    : []
                }
            />
        </Box>             
    )
}

// Свойства по умолчанию
MaterialSlider.defaultProps = {
    showTitle: false,
    showNavButtons: false,
}

// Экспорт компонента
export default MaterialSlider